export default {
  "post.noun.everything": "tudo",
  "noun.all.plural": "tudo",
  "adj.read.plural": "lido",
  "adj.unread.plural": "não lido",
  "noun.none": "nehuma",
  "action.archive": "arquivar",
  "action.restore": "restaurar",
  "action.delete": "apagar",
  "action.markRead": "marcar como lido",
  "action.markUnread": "marcar como não lido",
  "search": "Procurar",
  "PhiThreadFilters.Contains": "Contém",
  "PhiThreadFilters.From": "De",
  "PhiThreadFilters.Subject": "Assunto",
  "PhiThreadFilters.DateAround": "Encontro por volta",
  "PhiThreadFilters.day": "dia",
  "PhiThreadFilters.days": "dias",
  "PhiThreadFilters.week": "semana",
  "PhiThreadFilters.weeks": "semanas",
  "PhiThreadFilters.month": "mês",
  "PhiThreadFilters.Folder": "Pasta",
  "PhiThreadFilters.Inbox": "caixa de entrada",
  "PhiThreadFilters.Archive": "Arquivo",
  "PhiThreadFilters.Trash": "Lixo",
  "PhiThreadFilters.Type": "Tipo",
  "PhiThreadFilters.AnyType": "Todos os tipos",
  "PhiThreadFilters.Status": "Status",
  "PhiThreadFilters.StatusAny": "Lido e não lido",
  "PhiThreadFilters.StatusRead": "Lido",
  "PhiThreadFilters.StatusUnread": "não lido",
  "StateThreadFeed.Search": "Procurar",
  "StateThreadFeed.Cancel": "Cancelar",
  "notice.thereIsNothingHere": "Não há nada aqui",
  "PhiThreadFilters.DateAround": "Período"
}