export const download = (file)=>{

    if (typeof device == "undefined" || typeof device.platform == "undefined") {
        
        return window.open(file.downloadUrl ? file.downloadUrl : file.url, '_system');
    }

    var uri        = file.url;
    var fileName   = file.name.replace(/[^a-zA-Z0-9.]/g, "").toLowerCase();

    if (device.platform == "iOS") {
        progress.show("Download");
    } else {
        cordova.plugin.pDialog.init({progressStyle: 'SPINNER', cancelable: false,  title: "Download", message: fileName});
    }

    window.requestFileSystem  = window.requestFileSystem || window.webkitRequestFileSystem;
    window.requestFileSystem(window.TEMPORARY, parseInt(file.size), function (fs) 
    {
        fs.root.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) 
        {
            var toURL = fileEntry.toURL();

            cordova.plugin.http.downloadFile(
            uri,
            null,
            {"Content-Type" : file.mimetype, "Accept": file.mimetype},
            toURL,
            // success callback
            function(entry, response) {

                if (device.platform == "iOS") {
                    progress.hide();
                } else {
                    cordova.plugin.pDialog.dismiss();
                }

                window.PreviewAnyFile.previewPath(
                    win =>
                    {
                        if (win == "SUCCESS") {
                            console.log('success')
                        } else if (win == "CLOSING") {
                            console.log('closing')
                        } else if (win == "NO_APP") {
                            console.log('no suitable app to open the file (mainly will appear on android')
                        } else {
                            console.log('error')
                        }
                    },
                    error => console.error("open failed", error),
                    toURL
                );
            },
            // error callback
            function(response) {
                console.error(response.error);
            }
        );
        },
        function (err) { 
            console.error('error creatting file! '); 
            console.error(err); 

            if (device.platform == "iOS") {
                progress.hide();
            } else {
                cordova.plugin.pDialog.dismiss();
            }
        });
    }, 
    function (err) { 
        console.error('error getting persistent fs! ');
        console.log(err); 

        if (device.platform == "iOS") {
            progress.hide();
        } else {
            cordova.plugin.pDialog.dismiss();
        }
    });   
}

