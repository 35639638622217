<template>
    <div class="phi-page" :class="{fixed, scrollhide: !fixed, 'withHeaderImage': !!headerImage}">

        <slot name="top"></slot>

        <div class="phi-page-toolbar" :class="{hidden: toolbar.isHidden}">
            <div class="phi-page-toolbar-bg" :style="{backgroundColor: color}"></div>
            <div class="phi-page-toolbar-contents">
                <slot name="toolbar"></slot>
            </div>
        </div>

        <div class="phi-page-body">
            <div class="phi-page-header">
                <div class="header-image-container" v-if="headerImage">
                  <img :src="headerImage" width="100%" />
                </div>
                <slot v-else name="header"></slot>
            </div>

            <div class="phi-page-loader">
                <mu-linear-progress :color="color" v-show="loading"></mu-linear-progress>
            </div>

            <div class="phi-page-contents">
                <slot></slot>
            </div>
        </div>

        <div class="phi-page-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'phi-page',
    props: {
        parallax: {
            type: Number,
            default: 0.1
        },

        loading: {
            type: Boolean,
            default: false
        },

        /* Toolbar is always visible */
        fixed: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: "#1C89B8"
        },

        headerImage: {
            type: String,
            required: false,
            default: null
        }
    },

    data: () => ({
        toolbar: {
            isHidden: false
        },
        lastScrollPosition: 0
    }),

    mounted() {
        this.$el.querySelector('.phi-page-body').addEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll(event) {
            /* Background parallax */
            this.$el.style.backgroundPositionY = '-' + event.target.scrollTop*this.parallax + 'px';

            /* Toolbar behavior */
            var delta = event.target.scrollTop - this.lastScrollPosition;
            if (Math.abs(delta) > 23) {
                if (delta < 0) {
                    this.toolbar.isHidden = false;
                } else {
                    this.toolbar.isHidden = event.target.scrollTop > event.target.offsetTop;
                }
                this.lastScrollPosition = event.target.scrollTop;
            }

            /* Toolbar background opacity */
            var opacity = Math.min(event.target.scrollTop / this.$el.querySelector('.phi-page-contents').offsetTop, 1);
            // this.$el.querySelector('.phi-page-toolbar-bg').style.opacity = opacity;
        }
    }
}
</script>

<style lang="scss">
$phi-page-toolbar-height: 56px;

.phi-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;

    background-color: #f2f2f2;
    background-size: 100%;
    background-repeat: no-repeat;

    .header-image-container {
      overflow: hidden;
      max-height: 280px;
    }

    .phi-page-toolbar {
        height: $phi-page-toolbar-height;
        max-height: $phi-page-toolbar-height;
        position: relative;
        transition: top 200ms ease-out;

        transform: translateY(0);

        .phi-page-toolbar-bg,
        .phi-page-toolbar-contents {
            height: $phi-page-toolbar-height;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .phi-page-toolbar-bg {
            opacity: 0;
            z-index: 1;
        }

        .phi-page-toolbar-contents {
            z-index: 2;

            & > div {
                z-index: 2;
                height: $phi-page-toolbar-height;
                display: flex;
                align-items: center;
                padding-right: 16px;

                h1 {
                    flex: 1;
                    padding: 0 12px;
                    font-size: 18px;
                    color: #444;
                }

                button {
                    background-color: transparent;
                    border: none;
                    color: inherit;
                    padding: 12px 18px;
                    cursor: pointer;
                    font-size: 1em;
                }
            }
        }
    }

    .phi-page-body {
        flex: 1;

        overflow: hidden;
        overflow-y: scroll;
        // -webkit-overflow-scrolling: touch;  // fucks up toolbar z-index in ios
        // overscroll-behavior: none;

        display: flex;
        flex-direction: column;

        .phi-page-contents {
            flex: 1;

            background-color: #f2f2f2;
            padding: 6px;

            // espacio para que no se obstruya el contenido con la barra de navegación inferior...
            padding-bottom: 64px;
        }

        .phi-page-header {
            word-wrap: break-word;
        }
    }

    .phi-page-loader {
        height: 3px;
        min-height: 3px;

        .mu-linear-progress {
            height: 100%;
        }
    }

    .phi-page-footer {
        background-color: #f2f2f2;
        overflow-y: auto;
    }


    &.scrollhide {
        .phi-page-body {
            padding-top: $phi-page-toolbar-height;
        }

        .phi-page-toolbar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;

            padding-right: 16px;  /*leave room for scrollbars*/

            &.hidden {
                top: -$phi-page-toolbar-height;
            }

        }
    }
}


.phi-page.withHeaderImage {
    .phi-page-body {
        padding: 0;
    }
}
</style>