 
export default function() {

	let that = this;

	return new Promise((resolve, reject) => {
		window.cordova.plugins.SignInWithApple.signin(
			{ requestedScopes: [0, 1] },
			function(succ){

				console.info(succ);
				console.info("email: ".succ.email);

				if(succ.authorizationCode){

					sessionStorage.setItem('accessToken', succ.authorizationCode);
					sessionStorage.setItem('idToken', succ.identityToken);

					// Aqui hago el llamado al API para verificar que el usuario exista en Phidias
					that.post("oauth/apple", {email: succ.email, code: succ.authorizationCode})
					.then(response => resolve(that.setToken(response.access_token)));
				}else{
					reject()
				}
			},
			function(err){
				console.error(err);
				reject(err);
			}
		);

		var validateIdToken = function(callback) {
            if (null == sessionStorage.idToken || sessionStorage.idToken.length <= 0) {
                callback(false);
			}
		}
	}).catch(error => {
        reject(error);
    });
}

