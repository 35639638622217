import Vue from 'vue';

/* Muse-UI */
import MuseUI from 'muse-ui';
import '@/assets/fix-muse/muse-ui.css';
import '@/assets/fix-muse/theme-carbon.css'; // use carbon theme
Vue.use(MuseUI);

/* phidias.scss */
import '@/libraries/phidias.css/src/index.scss';

/* PhotoSwipe */
import VuePhotoSwipe from '@/components/PhotoSwipe/PhotoSwipe.vue';
Vue.use(VuePhotoSwipe);

/* Phi Global components */
import PhiPage from '@/components/Phi/Page.vue';
Vue.component('phi-page', PhiPage);

import PhiInput from '@/components/Phi/Input.vue';
Vue.component('phi-input', PhiInput);

// import PhiMap from '@/components/Phi/Map.vue';
// Vue.component('phi-map', PhiMap);

import PhiDrawer from '@/components/Phi/Drawer.vue';
Vue.component('phi-drawer', PhiDrawer);

import PhiPost from '@/components/Phi/Post.vue';
Vue.component('phi-post', PhiPost);

import PhiBlock from '@/components/Phi/Block.vue';
Vue.component('phi-block', PhiBlock);

// import PhiSticky from '@/directives/Phi/Sticky.js';
// Vue.directive('phi-sticky', PhiSticky);

// import PhiAutosize from '@/directives/Phi/Autosize.js';
// Vue.directive('phi-autosize', PhiAutosize);

import PhiHighlight from '@/directives/Phi/Highlight.js';
Vue.directive('phi-highlight', PhiHighlight);

// import QSwipe from '@/directives/Quasar/touch-swipe.js';
// Vue.directive('touch-swipe', QSwipe);

// import QPan from '@/directives/Quasar/touch-pan.js';
// Vue.directive('touch-pan', QPan);

// import QHold from '@/directives/Quasar/touch-hold.js';
// Vue.directive('touch-hold', QHold);

// import PhiStripTagsJs from '@/directives/Phi/StripTagsJs.js';
// Vue.directive('phi-striptagsjs', PhiStripTagsJs);