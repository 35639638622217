// export default function({webClientId = '824539354693-dm7184it8frp3o75ebrddj624kl1lcpo.apps.googleusercontent.com'}) {
export default function({webClientId = '890266961007.apps.googleusercontent.com'}) {

    /* Use cordova plug-in if present */
    if (typeof window.plugins != "undefined" && typeof window.plugins.googleplus != "undefined") {
        return new Promise((resolve, reject) => {
            window.plugins.googleplus.login(
                {
                    'scopes': 'email', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                    'webClientId': webClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                    'offline': true, // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
                },
                (obj) => {
                    /*
                    obj.email          // 'eddyverbruggen@gmail.com'
                    obj.userId         // user id
                    obj.displayName    // 'Eddy Verbruggen'
                    obj.familyName     // 'Verbruggen'
                    obj.givenName      // 'Eddy'
                    obj.imageUrl       // 'http://link-to-my-profilepic.google.com'
                    obj.idToken        // idToken that can be exchanged to verify user identity.
                    obj.serverAuthCode // Auth code that can be exchanged for an access token and refresh token for offline access
                    obj.accessToken    // OAuth2 access token
                    */

                    this.post("oauth/google", {code: obj.serverAuthCode})
                        .then(
                            response => {
                                
                                resolve(this.setToken(response.access_token));
                            },

                            err => { // Correct google login, but incorrect phidias login.
                                window.plugins.googleplus.disconnect();
                                reject(err);
                            }
                        );
                },
                function (msg) {
                    alert("Error: " + msg);
                    reject(msg);
                }
            );
        });
    }


    /* Use google's web authentication */
    return getGoogleAuthorizationCode(webClientId)
        .then(googleCode => {
            return this.post("oauth/google", {code: googleCode})
                .then( response => this.setToken(response.access_token) );
        });
}

function getGoogleAuthorizationCode(webClientId = '890266961007.apps.googleusercontent.com')
{
    // https://developers.google.com/identity/protocols/OAuth2UserAgent#formingtheurl
    var authUrl = "https://accounts.google.com/o/oauth2/v2/auth?" + serialize({
        "redirect_uri":  "https://www.phidias.co/googlesignin.html",
        "client_id":     webClientId,  // webClientId.split(".")[0],
        "scope":         "email",
        "response_type": "code",
        "prompt":        "select_account"
    });

    return new Promise(function (resolve, reject) {
        var authWindow = window.open(authUrl, '_blank');
        var listenMessage = function(event) {
            if (event.data.status == 'success') {
                resolve(event.data.code);
            } else {
                reject(event.data.error);
            }
            window.removeEventListener('message', listenMessage);
        }
        window.addEventListener('message', listenMessage);

        // Within phonegap, the created window will NOT have a window.opener, so
        // use this instead:
        authWindow.addEventListener('loadstart', function(e) {
            var url   = e.url;
            var code  = new RegExp(/\?code=(.+)$/).exec(url);
            var error = new RegExp(/\?error=(.+)$/).exec(url);

            var result = {};

            if (code) {
                result.status = 'success';
                result.code   = code[1];
            } else if (error) {
                result.status = 'error';
                result.error  = error[1];
            }

            if (code || error) {
                window.postMessage(result, "*");
                authWindow.close();
            }
        });

    });
}


function serialize(obj, prefix) {
    var str = [];
    for(var p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[' + p + ']' : p;
            var v = obj[p];

            if (v == null) {
                continue;
            }

            if (typeof v == 'object') {
                str.push(serialize(v, k));
            } else if (typeof v == 'number' || v.length > 0) {
                str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
    }

    return str.join('&');
}