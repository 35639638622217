function addMark(match) {
    return "«" + match + "»";
}

function highlightText(element, toHighlight) {

    if(!Array.isArray(toHighlight)){
        let str = toHighlight;
        toHighlight = [];
        toHighlight.push(str);
    }
    
    let openMark = "<mark>";
    let closeMark = "</mark>";
    let myOpenMark = "«";
    let myCloseMark = "»";
    
    let cache = element.hasAttribute("data-original") ? element.getAttribute("data-original") : element.innerHTML;
    
    let comparison = new RegExp(openMark, 'g');
    cache = cache.replace(comparison, myOpenMark);
    comparison = new RegExp(closeMark, 'g');
    cache = cache.replace(comparison, myCloseMark);
    
    toHighlight.forEach(word => {
        let comparison = new RegExp(word, 'gi');
        cache = cache.replace(comparison, addMark);
    });
    
    comparison = new RegExp(myOpenMark, 'g');
    cache = cache.replace(comparison, "<mark>");
    comparison = new RegExp(myCloseMark, 'g');
    cache = cache.replace(comparison, "</mark>");
    
    element.innerHTML = cache;
}

export default {
    inserted(el, binding) {
        el.setAttribute("data-original", el.innerHTML);
        highlightText(el, binding.value);
    }, 
        
    update(el, binding) {
        highlightText(el, binding.value);
    }
}