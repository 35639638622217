// import { getProperty } from './getset.js';
import numberToWords from './numberToWords.js'

function interpolate(string, sourceData, preserveUndefined = false) {

  // BEWARE.    typeof null === "object"  es true.  Thank you JS.
  if (!string) {
    return string;
  }

  if (string == "{{}}") {
    return sourceData;
  }

  if (Array.isArray(string)) {
    return string.map(item => interpolate(item, sourceData, preserveUndefined));
  }

  if (typeof string === "object") {
    let retval = {};
    for (let key in string) {
      if (string.hasOwnProperty(key)) {
        retval[key] = interpolate(string[key], sourceData, preserveUndefined);
      }
    }
    return retval;
  }

  if (typeof string === "string") {
    // https://stackoverflow.com/questions/6323417/how-do-i-retrieve-all-matches-for-a-regular-expression-in-javascript
    let re = /{{(.+?)}}/g;
    let m;

    let newValues = {};
    do {
      m = re.exec(string);
      if (m && !(m[0] in newValues)) {
        // let propertyName = m[1].trim();
        // let targetValue = getProperty(sourceData, propertyName);
        let strExpression = m[1].trim()
        strExpression = strExpression.replace('&gt;', '>').replace('&lt;', '<') // decode HTML entities < >
        let targetValue = evalExpression(strExpression, sourceData)

        if (targetValue === undefined) {
          if (preserveUndefined) {
            continue; // Si la propiedad no existe, se conserva la cadena (e.j. el texto se conserva '{{ propiedadQueNoExiste }}')
          }
          targetValue = '';
        }

        /*
        Convert object in substrings to json.  e.g.:

        "Hola {{ cosa }}!"
        {
            cosa: {
                soy: "un objeto"
            }
        }

        --->

        "Hola {soy:'un objeto'}!"

        */
        if (string != m[0] && typeof targetValue == "object") {
          targetValue = JSON.stringify(targetValue, null, 2);
        }

        newValues[m[0]] = targetValue;
      }
    } while (m);

    let retval = string;

    for (let word in newValues) {
      if (string == word) {
        return newValues[word];
      }

      // Replace all ocurrences of the word with the new value
      let wordRegex = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp special characters in search string
      retval = retval.replace(new RegExp(wordRegex, 'g'), newValues[word]);
    }

    return retval;
  }

  return string;
}

/*
evalExpression(" nombre + ' ' + apellido ", {nombre: 'a', apellido: 'b', genero: false })
*/
function evalExpression(expr, model) {
  const $format = {
    list(arr, translations = null) {
      if (!Array.isArray(arr)) {
        return arr
      }

      if (translations) {
        const translatedArr = []
        for (const [key, value] of Object.entries(translations)) {
          if (arr.includes(key)) {
            translatedArr.push(value)
          }
        }
        arr = translatedArr
      }
      return arr.join(', ')
    },

    ul(arr, translations = null) {
      if (!Array.isArray(arr)) {
        return arr
      }

      const translatedArr = []
      for (const [key, value] of Object.entries(translations)) {
        if (arr.includes(key)) {
          translatedArr.push(value)
        }
      }

      let retval = '<ul>'
      translatedArr.forEach((value) => {
        retval = retval + `<li>${value}</li>`
      })
      retval = retval + '</ul>'
      return retval
    },

    date(timestamp, locale = undefined, options = undefined) {
      return (new Date(timestamp * 1000)).toLocaleDateString(locale, options)
    },

    words(num) {
      return numberToWords(num)
    }
  }

  try {
    const fn = new Function(
      ['$format', Object.keys(model)],
      '"use strict"; var window = null; var document = null; var alert = null; var console = null; return ' + expr,
    )
    return fn($format, ...Object.values(model))
  } catch (err) {
    return undefined
  }
}

export default interpolate