import '@/modules/ui/style/index.scss';

const UiAvatar = () => import('./UiAvatar/UiAvatar.vue');
const UiButtonSelection = () => import('./UiButtonSelection/UiButtonSelection.vue');
const UiCalendar = () => import('./UiCalendar/UiCalendar.vue');
const UiCard = () => import('./UiCard/UiCard.vue');
const UiCardEditor = () => import('./UiCard/UiCardEditor.vue');
const UiChartPie = () => import('./UiChartPie/UiChartPie.vue');
const UiChatBubble = () => import('./UiChatBubble/UiChatBubble.vue');
const UiCollection = () => import('./UiCollection/UiCollection.vue');
const UiColorPicker = () => import('./UiColorPicker/UiColorPicker.vue');
const UiDataTable = () => import('./UiDataTable/UiDataTable.vue');
const UiDataDialog = () => import('./UiDataDialog/UiDataDialog.vue');
const UiDataDrawer = () => import('./UiDataDrawer/UiDataDrawer.vue');
const UiDataModel = () => import('./UiDataModel/UiDataModel.vue');
const UiDialog = () => import('./UiDialog/UiDialog.vue');
const UiDrawer = () => import('./UiDrawer/UiDrawer.vue');
const UiDropdown = () => import('./UiDropdown/UiDropdown.vue');
const UiEmpty = () => import('./UiEmpty/UiEmpty.vue');
const UiExpand = () => import('./UiExpand/UiExpand.vue');
const UiField = () => import('./UiField/UiField.vue');
const UiForm = () => import('./UiForm/UiForm.vue');
const UiFormGenerator = () => import('./UiFormGenerator/UiFormGenerator.vue');
const UiHistory = () => import('./UiHistory/UiHistory.vue');
const UiIcon = () => import('./UiIcon/UiIcon.vue');
const UiIconPicker = () => import('./UiIconPicker/UiIconPicker.vue');
const UiInput = () => import('./UiInput/UiInput.vue');
const UiInputCheckbox = () => import('./UiInputCheckbox/UiInputCheckbox.vue');
const UiInputCsv = () => import('./UiInputCsv/UiInputCsv.vue');
const UiCsvColumn = () => import('./UiInputCsv/CsvColumn.vue');
const UiInputDate = () => import('./UiInputDate/UiInputDate.vue');
const UiInputFile = () => import('./UiInputFile/UiInputFile.vue');
const UiInputJson = () => import('./UiInputJson/UiInputJson.vue');
const UiInputPassword = () => import('./UiInputPassword/UiInputPassword.vue');
const UiInputSearch = () => import('./UiInputSearch/UiInputSearch.vue');
const UiItem = () => import('./UiItem/UiItem.vue');
const UiItemEditor = () => import('./UiItemEditor/UiItemEditor.vue');
const UiList = () => import('./UiList/UiList.vue');
const UiLoading = () => import('./UiLoading/UiLoading.vue');
const UiMenu = () => import('./UiMenu/UiMenu.vue');
const UiOptgroup = () => import('./UiOptgroup/UiOptgroup.vue');
const UiOption = () => import('./UiOption/UiOption.vue');
const UiOutput = () => import('./UiOutput/UiOutput.vue');
const UiOverflow = () => import('./UiOverflow/UiOverflow.vue');
const UiPagination = () => import('./UiPagination/UiPagination.vue');
const UiPanel = () => import('./UiPanel/UiPanel.vue');
const UiPopover = () => import('./UiPopover/UiPopover.vue');
const UiRadial = () => import('./UiRadial/UiRadial.vue');
const UiSelect = () => import('./UiSelect/UiSelect.vue');
const UiSlider = () => import('./UiSlider/UiSlider.vue');
const UiSliderItem = () => import('./UiSliderItem/UiSliderItem.vue');
const UiSwitch = () => import('./UiSwitch/UiSwitch.vue');
const UiTab = () => import('./UiTabs/UiTab.vue');
const UiTabs = () => import('./UiTabs/UiTabs.vue');
const UiInfiniteScroll = () => import('./UiInfiniteScroll/UiInfiniteScroll.vue');
const UiToast = () => import('./UiToast/UiToast.vue');
const UiPicker = () => import('./UiPicker/UiPicker.vue');

export {
  UiAvatar,
  UiButtonSelection,
  UiCalendar,
  UiCard,
  UiCardEditor,
  UiChartPie,
  UiChatBubble,
  UiCollection,
  UiColorPicker,
  UiDataTable,
  UiDataDialog,
  UiDataDrawer,
  UiDataModel,
  UiDialog,
  UiDrawer,
  UiDropdown,
  UiEmpty,
  UiExpand,
  UiField,
  UiForm,
  UiFormGenerator,
  UiHistory,
  UiIcon,
  UiIconPicker,
  UiInput,
  UiInputCheckbox,
  UiInputCsv,
  UiCsvColumn,
  UiInputDate,
  UiInputFile,
  UiInputJson,
  UiInputPassword,
  UiInputSearch,
  UiItem,
  UiItemEditor,
  UiList,
  UiLoading,
  UiMenu,
  UiOptgroup,
  UiOption,
  UiOutput,
  UiOverflow,
  UiPagination,
  UiPanel,
  UiPopover,
  UiRadial,
  UiSelect,
  UiSlider,
  UiSliderItem,
  UiSwitch,
  UiTab,
  UiTabs,
  UiInfiniteScroll,
  UiToast,
  UiPicker,
};