import Vue from 'vue';

function getSlotId(date) {
    if (!date) {
        return null;
    }

    date = typeof date == "object" ? new Date(date.getTime()) : new Date(date*1000);
    return date.getFullYear().toString() + date.getMonth().toString() + date.getDate().toString();
}


export default {
    state: {
        index: {},
        feeds: [],
        filters: [],
        isLoading: false
    },

    getters: {
        events(state) {
            return (startDate) => {
                let slotId = getSlotId(startDate);
                if (typeof state.index[slotId] == "undefined") {
                    return [];
                }

                let retval = [];

                loop1:
                for (let k in state.index[slotId]) {
                    let event = state.index[slotId][k];

                    for (let fk = 0; fk < state.filters.length; fk++) {
                        let filter = state.filters[fk];
                        if (!filter.validate(event)) {
                            continue;
                        }

                        if (!filter.enabled) {
                            continue loop1;
                        }
                    }

                    retval.push(state.index[slotId][k]);
                }
                return retval;
            }
        },

        allEvents(state) {
            let retval = [];
            for (let slot in state.index) {
                for (let k in state.index[slot]) {
                    retval.push(state.index[slot][k]);
                }
            }

            return retval;
        }
    },

    actions: {
        // When ready, load events for the current month
        /*
        ready(context) {
            let startDate = new Date();
            startDate.setDate(1);
            startDate.setHours(0,0,0,0);

            let endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(1);
            endDate.setHours(0,0,0,0);

            context.rootState.api
                .get(`/people/${context.rootState.user.id}/calendar/feed`, {
                    start: startDate.getFullYear() + "-" + (startDate.getMonth()+1) + "-" + startDate.getDate(),
                    end: endDate.getFullYear() + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate()
                })
                .then(incomingEvents => context.dispatch("addEvents", incomingEvents));
        },
        */

        addEvents(context, events) {
            events.forEach(event => context.dispatch("addEvent", event));
        },

        addEvent(context, event) {

            /* Sanitizar eventos del nuevo formato (/1/communication/people/{personId}/events) */
            if (typeof event.dateStart !== 'undefined') {
                event.startDate = event.dateStart;
                event.endDate = event.dateEnd;
                event.allDay = event.isAllDay;
                event.title = event.subject || 'Sin título';
                event.secondary = event.secondary ? event.secondary : (event.card ? event.card.secondary : '');
                event.post = {
                    id: null,
                    type: event.type
                };
            }

            let start = new Date(event.startDate*1000);
            let end = new Date(event.endDate*1000);

            let loop = new Date(start);
            while (loop <= end) {
                context.commit("slotPush", {slotId: getSlotId(loop), event} );

                let nextDay = loop.setDate(loop.getDate()+1);
                loop = new Date(nextDay);
            }

            if (event.occurrences && event.occurrences.length) {
                event.occurrences.forEach(occurrence => {
                    occurrence.post = event.post;
                    context.dispatch("addEvent", occurrence);
                });
            }
        },



        /*
        {
            id: unique feed id (to prevent duplicates)
            enabled: true
            getEvents(startDate, endDate) {
                return a Promise that will resolve to an array of events
            }
            url: shortcut to:  fetchEvents(start, end) => url ? start=YYYY-MM-DD & end=YYYY-MM-DD
        }
        */
        addFeed({commit, state, rootState}, feed) {
            if (!feed || typeof feed != "object" || !feed.id) {
                return;
            }

            for (let k = 0; k < state.feeds.length; k++) {
                if (state.feeds[k].id == feed.id) {
                    return;
                }
            }

            if (feed.url) {
                feed.getEvents = (startDate, endDate) => {
                    let params = Object.assign({
                        start: startDate.getFullYear() + "-" + (startDate.getMonth()+1) + "-" + startDate.getDate(),
                        end: endDate.getFullYear() + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate()
                    }, feed.params);
                    return rootState.api.get(feed.url, params);
                }
            }

            if (typeof feed.enabled == "undefined") {
                feed.enabled = false;
            }

            if (feed.enabled === 0 || feed.enabled === "0" || feed.enabled === "false") {
                feed.enabled = false;
            }

            if (feed.enabled === 1 || feed.enabled === "1" || feed.enabled === "true") {
                feed.enabled = true;
            }

            commit("pushFeed", feed);
        },

        fetchEvents(context, date) {
            let startDate, endDate;

            if (Array.isArray(date)) {
                startDate = date[0];
                endDate = date[1];
            } else {
                startDate = new Date(date.getTime());
                startDate.setHours(0,0,0,0);

                endDate = new Date(date.getTime());
                endDate.setHours(23,59,59);
            }

            /* Fetch events from feeds */
            let nProcesses = context.state.feeds.length;
            if (nProcesses) {
                context.commit("setLoading", true);
            }

            for (let k = 0; k < context.state.feeds.length; k++) {
                let feed = context.state.feeds[k];

                if (!feed.enabled) {
                    nProcesses--;
                    continue;
                }

                feed.getEvents(startDate, endDate)
                    .then(incomingEvents => {
                        context.dispatch("addEvents", incomingEvents);

                        nProcesses--;
                        if (nProcesses == 0) {
                            context.commit("setLoading", false);
                        }
                    })
                    .catch(err => {
                        nProcesses--;
                        if (nProcesses == 0) {
                            context.commit("setLoading", false);
                        }
                    });
                    // BEWARE!!!  Safari is fucking up finally()  (iPad)

            }

            if (nProcesses == 0) {
                context.commit("setLoading", false);
            }
        }
    },

    mutations: {
        slotPush(state, {slotId, event}) {
            if (typeof state.index[slotId] == "undefined") {
                Vue.set(state.index, slotId, {})
            }
            Vue.set(state.index[slotId], event.id, event);
        },

        setLoading(state, loading) {
            state.isLoading = loading;
        },

        pushFeed(state, feed) {
            state.feeds.push(feed);
        },

        /*
        filter
        {
            id: xx,
            label: xx,
            color: xx,
            enabled: true,
            validate(event) {
                Boolean, if the event belongs to the filter
            }
        }
        */
        addEventFilter(state, filter) {
            if (!filter.id) {
                return;
            }

            for (let k = 0; k < state.filters.length; k++) {
                if (state.filters[k].id == filter.id) {
                    return;
                }
            }

            state.filters.push(filter);
        },

        toggleFilter(state, filter) {
            filter.enabled = !filter.enabled;
        }
    }
}