export default {
    "common.Accept": "speichern",
    "common.Add": "hinzufügen",
    "common.Cancel": "abbrechen",
    "common.Close": "schließen",
    "common.Delete": "löschen",
    "common.Save": "speichern",

    "common.accept": "speichern",
    "common.add": "hinzufügen",
    "common.cancel": "abbrechen",
    "common.close": "schließen",
    "common.delete": "löschen",
    "common.save": "speichern",

    "action.accept": "speichern",
    "action.add": "hinzufügen",
    "action.addCheckpoint": "Kontrollpunkt hinzufügen",
    "action.addCondition": "Bedingung hinzufügen",
    "action.addNewRule": "Regel hinzufügen",
    "action.addToCalendar": "im Kalender anzeigen",
    "action.allowReplies": "Antworten zulassen",
    "action.allowRepliesAll": "Antworten auf alle zulassen",
    "action.allowForward": "Weiterleiten zulassen",
    "action.archive": "archivieren",
    "action.attach": "anhängen",
    "action.cancel": "abbrechen",
    "action.clearCache": "Clear cache",
    "action.close": "schließen",
    "action.createNewType": "Typ hinzufügen",
    "action.delete": "löschen",
    "action.enable": "aktivieren",
    "action.deselect": "Auswahl aufheben",
    "action.Download": "herunterladen",
    "action.form.addField": "Feld hinzufügen",
    "action.form.RemoveField": "Feld löschen",
    "action.Forward": "Nachricht weiterleiten",
    "action.forward": "Nachricht weiterleiten",
    "action.HideRecipients": "Empfänger ausblenden",
    "action.highlightPost": "als \"wichtige Nachricht\" veröffentlichen",
    "action.logout": "Abmelden",
    "action.markRead": "als gelesen markieren",
    "action.markUnread": "als ungelesen markieren",
    "action.notify": "Benachrichtigungen senden",
    "action.pay": "bezahlen",
    "action.post.addAttachment": "Anhang anfügen",
    "action.redact": "verfassen",
    "action.repeatEvery": "alle wiederholen",
    "action.repeatOn": "wiederholen",
    "action.reply": "antworten",
    "action.replyAll": "allen antworten",
    "action.restore": "wiederherstellen",
    "action.schedulePublishDate": "Veröffentlichungsdatum festlegen",
    "action.scheduleExpirationDate": "Ablaufdatum einplanen",
    "action.save": "speichern",
    "action.select": "auswählen",
    "action.send": "senden",
    "action.sendAnswers": "Antworten senden",
    "action.sendAnswer": "Antwort senden",
    "action.Share": "teilen",
    "action.slideGps": "Schieben Sie GPS zu starten",
    "action.undo": "rückgängig machen",
    "action.tapHereToUploadFiles": "Bitte hier klicken um Dateien auszuwählen",
    "adj.allDay": "den ganzen Tag",
    "adj.Appreciation.expelled": "von der Schule verwiesen",
    "adj.Appreciation.mustRepeat": "muss wiederholen",
    "adj.Appreciation.promoted": "versetzt",
    "adj.Appreciation.toDefine": "zu definieren",
    "adj.ends": "endet",
    "adj.everyDay": "jeden Tag",
    "adj.everyMonth": "jeden Monat",
    "adj.everyWeek": "jede Woche",
    "adj.everyYear": "jedes Jahr",
    "adj.notIssued.plural": "Not issued",
    "adj.read.plural": "gelesen",
    "adj.repeats": "wiederholt sich",
    "adj.selected": "ausgewählt",
    "adj.statusActive": "aktiv",
    "adj.statusGraduated": "Graduiert",
    "adj.statusInactive": "inaktiv",
    "adj.statusInscribed": "eingeschrieben",
    "adj.statusPending": "ausstehend",
    "adj.statusSuspended": "nicht bestanden",
    "adj.statusWithdrawn": "Abgang während des sj",
    "adj.unread.plural": "ungelesen",
    "adj.unread.singular": "ungelesen",
    "atendant": "",
    "aunt": "Tante",
    "brother": "Bruder",
    "component.phi.debit.expirationDate": "Verfallsdatum",
    "component.phi.debit.interests": "Verzinsung",
    "component.phi.debit.issueDate": "Ausgabedatum",
    "compose.placeholder": "sag etwas ...",
    "condition.attributes.between": "zwischen",
    "condition.attributes.contains": "enthält",
    "condition.attributes.document": "Ausweisnummer",
    "condition.attributes.equalTo": "gleich",
    "condition.attributes.firstName": "Vorname",
    "condition.attributes.gender": "Geschlecht",
    "condition.attributes.greaterThan": "größer als",
    "condition.attributes.lastName": "Nachname",
    "condition.attributes.lessThan": "weniger als",
    "condition.attributes.startsWith": "beginnt mit",
    "cumulatives": "",
    "daughter": "Tochter",
    "employee": "Mitarbeiter",
    "everyone": "alle",
    "exactly": "genau",
    "father": "Vater",
    "female cousin": "Cousine",
    "graded equal to": "benotet mit",
    "graded greater than or equal to": "benotet mit besser oder gleich",
    "graded greater than": "benotet mit besser als",
    "graded less than or equal to": "benotet mit gleich oder schlechter",
    "graded less than": "benotet mit schlechter als",
    "grades": "Noten",
    "grade": "Noten",
    "granddaughter": "Enkelin",
    "grandfather": "Großvater",
    "grandmother": "Großmutter",
    "grandson": "Enkel",
    "group director": "Group director",
    "here": "hier",
    "I did not find anyone": "Kein Suchergebnis",
    "less than or exactly": "weniger oder genau",
    "less than": "weniger als",
    "launcher.Website": "Webseite",
    "launcher.CallSchool": "Schule anrufen",
    "launcher.Location": "Anfahrt",
    "male cousin": "Cousin",
    "member": "Mitglied",
    "more people": "more people",
    "more than or exactly": "mehr oder genau",
    "more than": "mehr als",
    "mother": "Mutter",
    "nav.calendar": "Kalender",
    "nav.contacts": "Kontakte",
    "nav.highlights": "Aktuell",
    "nav.inbox": "Posteingang",
    "nav.school": "Mehr",
    "nephew": "Neffe",
    "niece": "Nichte",
    "no": "nein",
    "noone": "niemband",
    "notice.loading": "Laden",
    "notice.noPeopleSelected": "Bitte mindestens einen Empfänger wählen",
    "notice.readByReadOfTotal": "{{read}} von {{total}} haben diese Nachricht gelesen",
    "notice.thereIsNothingHere": "Kein Eintrag vorhanden",
    "notice.toMe": "für mich",
    "notice.ToNPeople": "Für {{n}} Personen",
    "notice.ToOnePerson": "Für eine Person",
    "notice.gpsEnable": "Aktivieren Sie GPS",
    "notice.gpsDisable": "deaktiviere GPS",
    "noun.academicGroups": "Gruppen",
    "noun.academicYear": "Jahr",
    "noun.administration": "Verwaltung",
    "noun.agenda": "Agenda",
    "noun.all.plural": "alle",
    "noun.archived.plural": "Archivierte Nachrichlen",
    "noun.Balance": "",
    "noun.bcc": "Bcc",
    "noun.billing": "Fakturierung",
    "noun.busy": "beschäftigt",
    "noun.calendar": "Kalender",
    "noun.checkpoints": "Checkpoints",
    "noun.Checked": "ja",
    "noun.Unchecked": "nein",
    "noun.conceptsToPay": "offene Verbindlichkeiten",
    "noun.dashboard": "Dashboard",
    "noun.day": "Tag",
    "noun.dayOfMonth": "Tag des Monats",
    "noun.dayOfWeek": "Tag der Woche",
    "noun.days": "Tage",
    "noun.defaultValue": "standardmäßig",
    "noun.document": "Dokument",
    "noun.editable": "bearbeitbar",
    "noun.employee-group": "Mitarbeiter-Gruppe",
    "noun.Employees": "Mitarbeiter",
    "noun.email": "Post",
    "noun.addEmail": "Email hinzufügen",
    "noun.deleteEmail": "Möchten Sie diese E-Mail und alle Ihre Benachrichtigung seinstellungen löschen?",
    "noun.validEmail": "Die E-Mail hat kein gültiges Format",
    "noun.form": "Umfrage",
    "noun.form.description": "Beschreibung",
    "noun.form.description": "Beschreibung",
    "noun.form.options": "Optionen",
    "noun.form.title": "Titel",
    "noun.form.typeCheckbox": "checkbox",
    "noun.form.typeSelect": "Liste",
    "noun.form.typeText": "Text",
    "noun.form.typeTextarea": "Textfeld",
    "noun.files": "Dateien",
    "noun.general": "generell",
    "noun.googleDrive": "Google Drive",
    "noun.groups": "Gruppen",
    "noun.HTMLText": "HTML-Text",
    "noun.inbox": "Posteingang",
    "noun.language": "Sprache",
    "noun.Mentions": "",
    "noun.month": "Monat",
    "noun.modality": "Modalität",
    "noun.months": "Monate",
    "noun.mobileApp": "mobile App",
    "noun.name": "Name",
    "noun.namePlural": "Plural",
    "noun.nameSingular": "Singular",
    "noun.never": "nie ",
    "noun.none": "keine ",
    "noun.notifications": "Benachrichtigungen  ",
    "noun.nounPersonFemale": "die",
    "noun.nounPersonMale": "der",
    "noun.payedConcepts": "bezahlte Verbindlichkeiten",
    "noun.paymentsToApply": "Payments to apply",
    "noun.pendingCharges": "Pending invoices",
    "noun.People": "Personen",
    "noun.periods": "",
    "noun.post.preview": "Vorschau",
    "noun.post.modifyFiles": "Anhänge ändern",
    "noun.post.modifyPicture": "Bild ändern",
    "noun.post.delete": "Anhänge löschen",
    "noun.post.edit": "bearbeiten",
    "noun.post.editVideo": "Video bearbeiten",
    "noun.post.selectDocument": "Dokument auswählen",
    "noun.post.Title": "Betreff",
    "noun.postTypes": "Post types",
    "noun.prices": "Preise",
    "noun.recentPayments": "Recent payments",
    "noun.Relatives": "Eltern",
    "noun.righNow": "jetzt sofort",
    "noun.section": "Klasse",
    "noun.sent.plural": "Gesendete Nachrichten",
    "noun.sentTo.singular": "Empfänger",
    "noun.settings": "Einstellungen",
    "noun.status": "Status",
    "noun.studentCode": "Schülernummer",
    "noun.Students": "Schüler",
    "noun.to": "An",
    "noun.tracker": "Tracker",
    "noun.transport": "Transport",
    "noun.trash": "Papierkorb",
    "noun.type": "Typ",
    "noun.urlVideo": "Youtube oder Vimeo URL",
    "noun.urlVideoError": "Dies ist keine gültige Video-URL",
    "noun.value": "Wert",
    "noun.view": "Ansicht",
    "noun.video": "Video",
    "noun.week": "Woche",
    "noun.weeks": "Wochen",
    "noun.years": "Jahre",
    "notice.starting a": "Starten eines",
    "notice.routeFinish": "Fertig",
    "OK": "OK",
    "other": "weitere",
    "person.condition.attributes": "persönliche Angaben",
    "person.condition.debtor": "Zahlungsstand",
    "person.condition.enrollment": "Einschreibung",
    "person.condition.type": "Typ",
    "person.condition.v3Appreciation": "auswertung der einschreibungen",
    "person.condition.v3ChildrenWithEnrollment": "Kinder in Klasse",
    "person.condition.v3Class": "Lerngruppe",
    "person.condition.v3Debit": "Zahlungsplan",
    "person.condition.v3DebitPrice": "",
    "person.condition.v3EmployeeGroups": "Gruppe (Mitarbeiter)",
    "person.condition.v3FamilyWithXChildren": "In Familien mit N Kindern",
    "person.condition.V3Mentions": "Nennungen",
    "person.condition.V3PeopleSetMemberships": "",
    "person.condition.v3Role": "Rolle",
    "person.condition.v3Transport": "Transportweg",
    "person.condition.V3EnrollmentModality": "Modalität",
    "pick prices": "",
    "post.noun.everything": "alles",
    "post.settings.highlightOff": "nicht als \"wichtige Nachrichten\" veröffentlicht",
    "post.settings.highlightOn": "als \"wichtige Nachrichten\" veröffentlicht",
    "post.settings.notificationsOff": "Benachrichtigungen deaktiviert",
    "post.settings.notificationsOn": "Benachrichtigungen aktiviert",
    "post.settings.recipientsInvisible": "Empfänger unsichtbar",
    "post.settings.recipientsVisible": "Empfänger sichtbar",
    "post.settings.repliesOff": "keine Antwort",
    "post.settings.repliesOn": "Antworten aktiviert",
    "post.settings.repliesAllOn": "Anworten an alle aktiviert",
    "post.settings.repliesAllOff": "Antworten an alle deaktiviert",
    "post.settings.forwardOn": "Erneut senden aktiviert",
    "post.settings.forwardOff": "Weiterleiten aus",
    "prep.until": "until",
    "published": "veröffentlicht",
    "question.deleteThisEvent": "Dieses Ereignis löschen?",
    "question.deleteThisRule": "Diese Regel löschen?",
    "question.deleteThisFile": "Diese Dateien lösche?",
    "question.discardThisDraft": "Diesen Entwurf verwerfen?",
    "question.deleteThisVideo": "Dieses Video löschen?",
    "question.deleteThisText": "Dieses Text löschen?",
    "question.deleteThisForm": "Dieses Bilden löschen?",
    "relative": "Verwanter",
    "representant": "",
    "responsible": "",
    "search": "suchen",
    "searching": "",
    "sister": "Schwester",
    "son": "Sohn",
    "startRoute": "route starten",
    "stepdaughter": "Stieftochter",
    "stepfather": "Stiefvater",
    "stepmother": "Stiefmutter",
    "stepson": "Stiefsohn",
    "student": "Schüler",
    "teacher": "Lehrer",
    "substitute teacher": "Ersatzlehrer",
    "Total": "Gesamt",
    "try again": "versuchen Sie es erneut",
    "type.Visible": "sichtbar",
    "type.Invisible": "unsichtbar",
    "uncle": "Onkel",
    "yes": "Ja",
    "confirm.deleteFile": "Möchten Sie diesen Anhang tatsächlich löschen?",
    "events": "Ereignisse",
    "assignments": "Aufgaben",
    "exams": "Prüfungen",
    "evaluations": "Bewertungen",

    "register.form.birthday": "Geburtstag",
    "register.form.cancel": "Abbrechen",
    "register.form.children": "Schüler",
    "register.form.children2": "Für Schüler",
    "register.form.course": "Klasse",
    "register.form.email": "E-Mail",
    "register.form.document": "Document",
    "register.form.firstName": "Vorname",
    "register.form.gender": "Geschlecht",
    "register.form.lastName": "Nachname",
    "register.form.lastName2": "Zweiter Nachname",
    "register.form.loading": "Registering ...",
    "register.form.submit": "anmelden",
    "register.form.password": "Passwort",
    "register.form.success.title": "Prüfen Sie Ihre E-Mail!",
    "register.form.success.email": "Wir haben eine Nachricht an <strong>{{email}}</strong> mit Anweisungen zum Fortfahren gesendet",
    "register.form.verifyPassword": "Passwort bestätigen",
    "register.form.error.children.empty": "Sie müssen mindestens einen Sohn auswählen",

    "register.form.addStudent": "Schüler hinzufügen",
    "register.form.course.pick": "--Wählen Sie eine Klasse--",
    "register.form.gender.female": "weiblich",
    "register.form.gender.male": "männlich",
    "register.form.error.course.empty": "Sie müssen einen Kurs wählen",
    "register.form.error.document.empty": "Sie müssen ein Dokument eingeben",
    "register.form.error.firstName.empty": "Sie müssen einen Vornamen eingeben",
    "register.form.error.lastName.empty": "Sie müssen einen Nachnamen eingeben",
    "register.form.error.birthday.empty": "Sie müssen ein Datum wählen",
    "register.form.error.password.empty": "Sie müssen ein Passwort eingeben",
    "register.form.error.password.mismatch": "Passwörter stimmen nicht überein",
    "register.form.error.email.empty": "Sie müssen eine E-Mail eingeben",
    "register.form.error.email.invalid": "Ungültige Adresse",
    "register.form.error.email.taken": "Diese E-Mail wird bereits verwendet",
    "register.form.addRelative": "Angehörigen hinzufügen",
    "register.continueToProcess": "Fortsetzen",

    "transport.email": "eMail",
    "transport.sms": "SMS",
    "transport.apn": "App (iOS)",
    "transport.gcm": "App (Android)",

    "launcher.billing": "Fakturierung",
    "launcher.messaging": "Kommunikation",
    "launcher.transport": "Transportweg",

    "downloading": "Wird Heruntergeladen",
    "error downloading file": "Fehler beim Herunterladen der Datei",

    "credits.l1": "Entwickelt von Phidias SAS",
    "credits.l2": "für die",
    "credits.l3": "Alle Rechte vorbehalten",
    "clinic.blood_type": "blutgruppe",
    "clinic.weight": "Gewicht",
    "clinic.height": "Höhe",
    "clinic.medical_insurance_EPS": "Krankenversicherung und / oder EPS",
    "noun.mobile": "Mobiltelefon",

    // terminos notificaciones singular
    "Ausencia": "Abwesenheit",
    "Aviso Urgente": "Wichtige Notiz",
    "Boletin": "Bekanntmachung",
    "Circular": "Kreisförmig",
    "clasificado": "Anzeigen",
    "cobro": "Zahlung",
    "Comunicado": "Freisetzung",
    "Documento": "Dokumentieren",
    "Encuesta": "Umfrage",
    "Evento": "Fall",
    "evento": "Fall",
    "Mensaje": "Nachricht",
    "mensaje": "Nachricht",
    "Noticia": "Nachrichten",
    "notificacion": "Benachrichtigung",
    "novedad de transporte": "Transport-Neuigkeiten",
    "pago": "Zahlen",
    "proceso": "Prozess",
    "reunion": "Wiedervereinigung",
    "seguimiento": "nachverfolgen",
    "tarea": "Aufgabe",

    // terminos notificaciones plural
    "Ausencias": "Abwesenheiten",
    "Avisos Urgentes": "Dringende Mitteilungen",
    "Boletines": "Newsletter",
    "Circulares": "Rundschreiben",
    "clasificados": "Kleinanzeigen",
    "cobros": "Gebühren",
    "Comunicados": "Ankündigungen",
    "Documentos": "Unterlagen",
    "Encuestas": "Umfragen",
    "Eventos": "Veranstaltungen",
    "mensajes": "Mitteilungen",
    "Noticias": "Nachrichten",
    "notificaciones": "Benachrichtigungen",
    "novedades de transporte": "Transport Nachrichten",
    "pagos": "Zahlungen",
    "procesos": "Prozesse",
    "reuniones": "Wiedervereinigungen",
    "seguimientos": "Nachverfolgungen",
    "tareas": "Hausarbeiten",

    "launcher.classroom": "Klassenzimmer",
    "the message is expired": "die Nachricht ist abgelaufen",

    "privacy policies": "Datenschutzrichtlinien",
    "read privacy policies": "Datenschutzrichtlinien lesen",
    "attached files": "beigefügte Anhänge",
    "accept privacy policies": "Datenschutzrichtlinien akzeptieren",


    "ausencia": "Fehitage",
    "Accounting": "Abrechnung",
    "boletin": "Zeugnisse",
    "Notas": "Noten",
    "export to csv": "Exportieren nach csv",

    "PhiThreadFilters.Contains": "enthält",
    "PhiThreadFilters.From": "In",
    "PhiThreadFilters.Subject": "Thema",
    "PhiThreadFilters.DateAround": "Treffen Sie sich",
    "PhiThreadFilters.day": "Tag",
    "PhiThreadFilters.days": "Tage",
    "PhiThreadFilters.week": "Woche",
    "PhiThreadFilters.weeks": "Wochen",
    "PhiThreadFilters.month": "Monat",
    "PhiThreadFilters.Folder": "Ordner",
    "PhiThreadFilters.Inbox": "Posteingang",
    "PhiThreadFilters.Archive": "Datei",
    "PhiThreadFilters.Trash": "Müll",
    "PhiThreadFilters.Type": "Typ",
    "PhiThreadFilters.AnyType": "Alle Arten",
    "PhiThreadFilters.Status": "Status",
    "PhiThreadFilters.StatusAny": "Gelesen und ungelesen",
    "PhiThreadFilters.StatusRead": "Gelesen",
    "PhiThreadFilters.StatusUnread": "Ungelesen",
    "StateThreadFeed.Search": "Suchen nach",
    "StateThreadFeed.Cancel": "Stornieren",
    "PhiThreadFilters.DateAround": "Zeitraum"
}
