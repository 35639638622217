<template>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

        <!-- Background of PhotoSwipe.
            It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                    <button class="pswp__button pswp__button--share" title="Share"></button>

                    <button class="pswp__button pswp__button--download" title="Download"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden">
                    <div class="pswp__share-tooltip"></div>
                </div>

                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>

                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>

                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import PhotoSwipe from 'photoswipe'
import PhotoSwipeDefaultUI from './photoswipe-ui-default'

var photoswipeOpen = false;  // !!! OH GOD
let $vm;

export default {
    data() {
        return {
            photoswipe: null,
			realViewportWidth: null,
			useLargeImages: false,
			firstResize: true,
			imageSrcWillChange: null
        }
    },

    methods: {
        open (index, items, options = {
            // captionEl: false,
            fullscreenEl: false,
            history: true,
            shareEl: false,
            tapToClose: false
        }) {

            const opts = Object.assign({
                index: index,
                getThumbBoundsFn (index) {
                    const thumbnail = document.querySelectorAll('.preview-img-item')[index]

                    if (!thumbnail) {
                        return;
                    }

                    const pageYScroll = window.pageYScroll || document.documentElement.scrollTop
                    const rect = thumbnail.getBoundingClientRect()
                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    }
                }
            }, options)

            this.photoswipe = new PhotoSwipe(this.$el, PhotoSwipeDefaultUI, items, opts)

			this.photoswipe.listen('beforeResize', () => {
				this.realViewportWidth = this.photoswipe.viewportSize.x * window.devicePixelRatio;

				if(this.useLargeImages && this.realViewportWidth < 1000) {
					this.useLargeImages = false;
					this.imageSrcWillChange = true;
				} else if(!this.useLargeImages && this.realViewportWidth >= 1000) {
					this.useLargeImages = true;
					this.imageSrcWillChange = true;
				}

				if(this.imageSrcWillChange && !this.firstResize) {
					this.photoswipe.invalidateCurrItems();
				}

				if(this.firstResize) {
					this.firstResize = false;
				}

				this.imageSrcWillChange = false;
			});

            this.photoswipe.listen('gettingData', (index, item) => {

                photoswipeOpen = true;
                let thumbnail = document.querySelectorAll('.preview-img-item')[index];

                let clone = thumbnail.cloneNode();
                document.body.appendChild(clone);

                if ( this.useLargeImages ) {
                    item.w = clone.offsetWidth * 16;
                    item.h = clone.offsetHeight * 16;
                } else {
                    item.w = clone.offsetWidth * 8;
                    item.h = clone.offsetHeight * 8;
                }

                document.body.removeChild(clone);

            });

            this.photoswipe.init();
            document.querySelector('.pswp--open').style.zIndex = '99999';
        },

        close () {
            document.querySelector('.pswp--open').style.zIndex = '0';
            this.photoswipe.close()
        }
    },

    install(Vue) {
        const PhotoSwipe = Vue.extend(this)

        if (!$vm) {
            $vm = new PhotoSwipe({el: document.createElement('div')})
            document.body.appendChild($vm.$el)
        }

        Vue.$photoswipe = {
            open (index, items, options) {
                $vm.open(index, items, options)
            },
            close () {
                $vm.close()
            },
            getInstance() {
                return $vm.photoswipe
            },
            getCurrentItem() {
                return $vm.photoswipe.currItem
            }
        }

        Vue.mixin({
            created () {
                this.$photoswipe = Vue.$photoswipe
            }
        })
    }
}
</script>