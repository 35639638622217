export default {
    "common.Accept": "Accepter",
    "common.Add": "Ajouter",
    "common.Cancel": "abandonner",
    "common.Close": "Fermer",
    "common.Delete": "Supprimer",
    "common.Save": "Sauvegarder",

    "common.accept": "accepter",
    "common.add": "ajouter",
    "common.cancel": "abandonner",
    "common.close": "fermer",
    "common.delete": "supprimer",
    "common.save": "sauvegarder",
}