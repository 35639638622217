/*
Este es un singleton para manejar notificaciones automaticamente en browser y/o cordova mediante un API sencillo
Basado en https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md

notifications.subscribe()
    .then(device => {
        device:
            token,
            platform: window.device.platform == 'Android' ? 'gcm' : window.device.platform,
            model:    window.device.model,
            uuid:     window.device.uuid
        // post the token to the API (and/or save it to the store)
    });

notifications.on("notification", notification => {
    notification:
        type
        data
        native
});

notifications.on("error", ...);
notifications.unsubscribe();
*/

import serviceWokerNotifications from './serviceWorker.js';
import cordovaNotifications from './cordova.js';

export default {
    device: null,
    handler: null,

    listeners: {
        notification: [],
        error: []
    },

    subscribe() {
        return new Promise((resolve, reject) => {
            let isPhone = document.URL.indexOf("http://") === -1 && document.URL.indexOf("https://") === -1;
            if (isPhone) {
                document.addEventListener("deviceready", () => {
                    this._subscribe(cordovaNotifications, resolve, reject);
                }, false);
            } else {
                this._subscribe(serviceWokerNotifications, resolve, reject);
            }
        });
    },

    _subscribe(handler, resolve, reject) {

        /* Set up handlers first, so that when a coldstart notification ocurrs (right during subscribe), the listeners are present */
        handler.onNotification(event => {
            this.listeners["notification"].forEach(listener => listener(event));
        });

        handler.subscribe()
            .then(device => {
                this.handler = handler;
                this.device  = device;
                resolve(device);
            })
            .catch(err => {
                reject(err);
            });
    },

    unsubscribe() {
        if (!this.handler) {
            return;
        }
        return this.handler.unsubscribe();
    },

    on(event, handler) {
        if (typeof this.listeners[event] == "undefined") {
            throw `Invalid event '${event}'`;
        }
        this.listeners[event].push(handler);
    },

    dispatch(event, payload) {
        if (typeof this.listeners[event] == "undefined") {
            throw `Invalid event '${event}'`;
        }
        this.listeners[event].forEach(handler => handler(payload));
    }
}