<template>
	<div class="phi-input" :class="{_focused: focused, _dirty: !!value}">
		<input type="text" :value="value" @input="input" @change="change" @focus="focus" @blur="blur" @keydown.enter="$emit('enter')" @keydown="keydown"/>
		<label v-text="label"></label>
	</div>
</template>

<script>
export default {
	name: "phi-input",

	props: {
		value: null,
		label: String
	},

	data() {
		return {
			focused: false
		}
	},

	methods: {
		input(event) {
			this.$emit("input", event.target.value);
		},

		change(event) {
			this.$emit("change", event.target.value);
		},

		focus(event) {
			this.focused = true;
			this.$emit("focus", event);
		},

		blur(event) {
			this.focused = false;
			this.$emit("blur", event);
		},

		keydown(event){
			this.$emit("keydown", event);
		}
	},

	mounted() {
		var input = this.$el.querySelector("input");

		/* Copy all attributes into <input> element */
		if (this.$el.hasAttributes()) {
			for (var i = 0; i < this.$el.attributes.length; i++) {
				if (this.$el.attributes[i].name == "class") {
					continue;
				}
				input.setAttribute(this.$el.attributes[i].name, this.$el.attributes[i].value);
			}
		}
	}
}
</script>

<style lang="scss">
.phi-input {

	display: inline-block;
	min-width: 128px;
	position: relative;

	input,
	label {
		padding: 3px 0;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
	}

	label {
		display: block;
		text-align: left;
		color: inherit;
		opacity: 0.8;

		transform-origin: 0 0;
		transition: transform 200ms ease;
	}

	input {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		border: 0;
		background: transparent;
		border-bottom: 1px solid #ccc;

		z-index: 2;
	}

	/* underline */
	&::after {
		content: "";
		display: block;
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		z-index: 3;

		border-bottom: 2px solid var(--phi-color-main);
		transform-origin: 50% 50%;
		transform: scale(0, 1);
		transition: transform 200ms ease;
	}

	&._focused::after {
		transform: scale(1, 1);
	}

	/* displaced label */
	&._dirty label,
	&._focused label {
		color: var(--phi-color-main);
		transform: translate3d(0, -1em, 0) scale(0.618033);
	}
}
</style>
