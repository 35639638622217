export default {
	"de" : {
		"action.cancel" : "abbrechen",
		"action.enable" : "aktivieren",
		"notice.enableLocalizationToGetPosition" : "Bestimmen Sie Ihren Standort",
		"notice.errorLocationAccuracy" : "Fehler beim Abrufen der Positionsgenauigkeit",
		"notice.gpsLocationAccuracy" : "Bitte aktivieren Sie GPS auf Ihrem Gerät für eine genauere Lage",
		"notice.gpsSuccessfullEnable" : "GPS aktiv",
		"notice.positionNotAvailable" : "Ort nicht verfügbar",
		"notice.requestInProgress" : "Eine Anwendung ist im Gange",
		"notice.establishingYourPosition" : "Setzen Sie Ihren Standort",
		"notice.userDeniedPermissionLocation" : "Der Benutzer hat Zugriff verweigert ihren Standort wissen",
		"notice.permissionDenied" : "Erlaubnis verweigert",
		"noun.settings" : "Konfigurierung",
		"notice.location.permission" : "Transmitiendo Posición",
		"notice.transport" : "Transporte",
		"location.permission": "Standortberechtigungen",
		"request.location.permission" : "Diese Anwendung erfordert im Hintergrund Zugriff auf Ihren Standort, um den Eltern die Position der Schulroute anzuzeigen, wenn die Anwendung nicht verwendet wird und das Transportmodul aktiviert ist.",
	
	},
	"es" : {
		"action.cancel" : "Cancelar",
		"action.enable" : "Habilitar",
		"notice.enableLocalizationToGetPosition" : "Determinar tu ubicación",
		"notice.errorLocationAccuracy" : "Error al solicitar precisión de posición",
		"notice.gpsLocationAccuracy" : "Por favor habilite el GPS en su dispositivo para una ubicación más precisa",
		"notice.gpsSuccessfullEnable" : "GPS activo",
		"notice.positionNotAvailable" : "Posición no disponible",
		"notice.requestInProgress" : "Una solicitd está en progreso",
		"notice.establishingYourPosition" : "Estableciendo su ubicación",
		"notice.permissionDenied" : "Permiso denegado",
		"notice.userDeniedPermissionLocation" : "El usuario ha denegado el acceso a conocer su ubicación",
		"noun.settings" : "Configuración",
		"location.permission": "Permisos de Ubicación",
		"notice.location.permission" : "Transmitiendo Posición",
		"notice.transport" : "Transporte",
		"request.location.permission" : "Esta aplicación requiere acceso a tu ubicación en segundo plano para mostrar a los padres de familia la posición de la ruta escolar cuando la aplicacion no esta en uso y esta habilitado el modulo de transporte.",	
	},
	"en" : {
		"action.cancel" : "Cancel",
		"action.enable" : "Enable",
		"notice.enableLocalizationToGetPosition" : "Determine your position",
		"notice.errorLocationAccuracy" : "Can not request location accuracy",
		"notice.gpsLocationAccuracy" : "Please turn loccation on (GPS) to improve your position",
		"notice.gpsSuccessfullEnable" : "GPS enable",
		"notice.positionNotAvailable" : "Position is not available",
		"notice.permissionDenied" : "Permission denied",
		"notice.requestInProgress" : "A request is in progress",
		"notice.establishingYourPosition" : "setting your location",
		"notice.userDeniedPermissionLocation" : "User has denied permission to use location service",
		"noun.settings" : "Settings",
		"location.permission": "Location Permissions ",
		"notice.location.permission" : "Transmitiendo Posición",
		"notice.transport" : "Transporte",
		"request.location.permission" : "This application requires access to your location in the background to show parents the position of the school route when the application is not in use and the transport module is enabled.",
	
	},
	"fr" : {
		"action.cancel" : "abandonner",
		"action.enable" : "activer",
		"notice.enableLocalizationToGetPosition" : "Déterminez votre emplacement",
		"notice.errorLocationAccuracy" : "Impossible d'obtenir la précision de position",
		"notice.gpsLocationAccuracy" : "S'il vous plaît activer le GPS sur votre appareil pour l'emplacement plus précis",
		"notice.gpsSuccessfullEnable" : "GPS actif",
		"notice.positionNotAvailable" : "emplacement indisponible",
		"notice.requestInProgress" : "Une application est en cours",
		"notice.permissionDenied" : "permission refusée",
		"notice.establishingYourPosition" : "Définissez votre emplacement",
		"notice.userDeniedPermissionLocation" : "L'utilisateur a refusé l'accès de leur emplacement de savoir",
		"noun.settings" : "Configuration",
		"location.permission": "Autorisations de localisation",
		"notice.location.permission" : "Transmitiendo Posición",
		"notice.transport" : "Transporte",
		"request.location.permission" : "Cette application nécessite un accès à votre localisation en arrière-plan pour montrer aux parents la position du parcours scolaire lorsque l'application n'est pas utilisée et que le module de transport est activé.",	
	},
	"it" : {
		"action.cancel" : "Annulla",
		"action.enable" : "Abilita",
		"notice.enableLocalizationToGetPosition" : "Determina la tua posizione",
		"notice.errorLocationAccuracy" : "Abilita il GPS sul tuo dispositivo per una posizione più precisa",
		"notice.gpsLocationAccuracy" : "Abilita il GPS sul tuo dispositivo per una posizione più precisa",
		"notice.gpsSuccessfullEnable" : "GPS attivo",
		"notice.positionNotAvailable" : "Posizione non disponibile",
		"notice.requestInProgress" : "Una richiesta è in corso",
		"notice.establishingYourPosition" : "Stabilire la tua posizione",
		"notice.permissionDenied" : "Autorizzazione negata",
		"notice.userDeniedPermissionLocation" : "L'utente ha negato l'accesso per conoscere la loro posizione",
		"noun.settings" : "Configurazione",
		"location.permission": "Permessi di posizione",
		"notice.location.permission" : "Transmitiendo Posición",
		"notice.transport" : "Transporte",
		"request.location.permission" : "Questa applicazione richiede l'accesso alla tua posizione in background per mostrare ai genitori la posizione del percorso scolastico quando l'applicazione non è in uso e il modulo di trasporto è abilitato.", 
	},
}