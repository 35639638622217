export default {
	"common.Accept": "Aceptar",
	"common.Add": "Agregar",
	"common.Cancel": "Cancelar",
	"common.Close": "Cerrar",
	"common.Delete": "Eliminar",
	"common.Save": "Guardar",

	"common.accept": "aceptar",
	"common.add": "agregar",
	"common.cancel": "cancelar",
	"common.close": "cerrar",
	"common.delete": "eliminar",
	"common.save": "guardar"
}
