/*
    Office 365 Login: Begin
    https://apps.dev.microsoft.com/
    https://docs.microsoft.com/en-us/outlook/rest/javascript-tutorial
    https://github.com/OfficeDev/office-js-docs-pr/blob/master/docs/develop/sso-in-office-add-ins.md
    https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-tokens
*/
var jsr = require('jsrsasign');

function guid() {
    var buf      = new Uint16Array(8);
    var cryptObj = window.crypto || window.msCrypto; // For IE11
    if (cryptObj === undefined || cryptObj.getRandomValues === 'undefined') {
        return;
    }
    cryptObj.getRandomValues(buf);
    function s4(num) {
        var ret = num.toString(16);
        while (ret.length < 4) {
            ret = '0' + ret;
        }
        return ret;
    }
    return s4(buf[0]) + s4(buf[1]) + '-' + s4(buf[2]) + '-' + s4(buf[3]) + '-' + s4(buf[4]) + '-' + s4(buf[5]) + s4(buf[6]) + s4(buf[7]);
}

function queryParams(source) {
    var array = [];
    for (var key in source) {
        array.push(encodeURIComponent(key) + "=" + encodeURIComponent(source[key]));
    }
    return 	array.join("&");
}

function buildAuthUrl(authEndpoint, redirectUri, appId, scopes) {
    sessionStorage.setItem('authState', guid());
    sessionStorage.setItem('authNonce', guid());

    var authParams = {
        response_type: 'id_token token',
        client_id: appId,
        redirect_uri: redirectUri,
        scope: scopes,
        state: sessionStorage.getItem('authState'),
        nonce: sessionStorage.getItem('authNonce'),
        response_mode: 'fragment'
    };
    var q = authEndpoint + queryParams(authParams);
    return q;
}

export default function() {
    let that = this;
    return new Promise((resolve, reject) => {
        var authEndpoint = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';
        var redirectUri  = 'https://www.phidias.co/officesignin.html';
        var appId        = '0328fd5a-f5a9-4233-b784-082c87d3c29e';
        //var pass       = 'cnpWWW501:voarNONV21~$:';
        // scoped 		 = 'openid profile email User.Read Mail.Read';
        var scopes       = 'openid email User.Read';
        var q            = buildAuthUrl(authEndpoint, redirectUri, appId, scopes);
        var options      = "EnableViewPortScale=yes,location=yes,toolbarcolor=#064b84,navigationbuttoncolor=#ffffff,closebuttoncolor=#ffffff,zoom=no";
        var regex 	     = /https:\/\/www\.phidias\.co\/officesignin\.html/gm;

        var authWindow   = window.open(q, '_blank', options);
        var loop 		 = '';

        if (isMobile) {
            authWindow.addEventListener("loadstop", function(event) {
                loop = setInterval(function() {

                    if (regex.exec(event.url) != null) {
                        authWindow.executeScript({ code: "localStorage.getItem('access_token')" },
                            function(values) {
                                var access_token = values[0];
                                if (access_token) {
                                    access_token = { data: JSON.parse(access_token) };
                                    authWindow.close();
                                    listenMessage(access_token);
                                }
                            }
                        );
                    }
                }, 3000);
            });
        }

        // Listen (one time) for messages sent from authWindow
        var listenMessage = function(event) {
            if (isMobile) { clearInterval(loop); }

            if (event.data.success == 'ok') {
                handleTokenResponse(event.data.hash_token);
                validateIdToken(response => {

                    // Aqui hago el llamado al API para verificar que el usuario exista en Phidias
                    that.post("oauth/office", {code: response.email})
                        .then(response => resolve(that.setToken(response.access_token)));
                });
            } else {
                reject();
            }

            !isMobile ? window.removeEventListener('message', listenMessage) : '';
        }

        var parseHashParams = function(hash) {
            var params = hash.slice(1).split('&');

            var paramarray = {};
            params.forEach(function(param) {
                param = param.split('=');
                paramarray[param[0]] = param[1];
            });
            return paramarray;
        }

        var handleTokenResponse = function(access_token) {
            // clear tokens
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('idToken');

            var tokenresponse = parseHashParams(access_token);

            // Check that state is what we sent in sign in request
            if (tokenresponse.state != sessionStorage.getItem('authState')) {
                sessionStorage.removeItem('authState');
                sessionStorage.removeItem('authNonce');
                // Report error
                window.location.hash = '#error=Invalid+state&error_description=The+state+in+the+authorization+response+did+not+match+the+expected+value.+Please+try+signing+in+again.';
                return;
            }

            sessionStorage.setItem('authState', '');
            sessionStorage.setItem('accessToken', tokenresponse.access_token);

            // Get the number of seconds the token is valid for,
            // Subract 5 minutes (300 sec) to account for differences in clock settings
            // Convert to milliseconds
            var expiresin  = (parseInt(tokenresponse.expires_in) - 300) * 1000;
            var now        = new Date();
            var expireDate = new Date(now.getTime() + expiresin);
            sessionStorage.setItem('tokenExpires',expireDate.getTime());

            sessionStorage.setItem('idToken', tokenresponse.id_token);

            // Redirect to home page
            window.location.hash = '#';
        }

        var validateIdToken = function(callback) {
            if (null == sessionStorage.idToken || sessionStorage.idToken.length <= 0) {
                callback(false);
            }

            // JWT is in three parts seperated by '.'
            var tokenParts = sessionStorage.idToken.split('.');
            if (tokenParts.length != 3){
                callback(false);
            }

            // Parse the token parts
            var header  = jsr.KJUR.jws.JWS.readSafeJSONString(jsr.b64utoutf8(tokenParts[0]));
            var payload = jsr.KJUR.jws.JWS.readSafeJSONString(jsr.b64utoutf8(tokenParts[1]));

            // Check the nonce
            if (payload.nonce != sessionStorage.authNonce) {
                sessionStorage.authNonce = '';
                callback(false);
            }

            sessionStorage.authNonce = '';

            // Check the audience
            if (payload.aud != appId) {
                callback(false);
            }

            // Check the issuer Should be https://login.microsoftonline.com/{tenantid}/v2.0
            if (payload.iss !== 'https://login.microsoftonline.com/' + payload.tid + '/v2.0') {
                callback(false);
            }

            // Check the valid dates
            var now       = new Date();
            // To allow for slight inconsistencies in system clocks, adjust by 5 minutes
            var notBefore = new Date((payload.nbf - 300) * 1000);
            var expires   = new Date((payload.exp + 300) * 1000);
            if (now < notBefore || now > expires) {
                callback(false);
            }

            // Now that we've passed our checks, save the bits of data
            // we need from the token.

            sessionStorage.userDisplayName = payload.name;
            sessionStorage.userSigninName  = payload.preferred_username;

            // Per the docs at:
            // https://azure.microsoft.com/en-us/documentation/articles/active-directory-v2-protocols-implicit/#send-the-sign-in-request
            // Check if this is a consumer account so we can set domain_hint properly
            sessionStorage.userDomainType = payload.tid === '9188040d-6c67-4c5b-b112-36a304b66dad' ? 'consumers' : 'organizations';

            callback(payload);
        }

        if (!isMobile) { window.addEventListener('message', listenMessage); }

    }).catch(error => {
        reject(error);
    });
}
/* Office 365 Login: End */