// Faux app: bridge to Vuex
import store from './index.js';

export default {
    get api() {
        return store.state.api;
    },

    get user() {
        return store.state.user;
    },

    get data() {
        return store.state.settings ? store.state.settings : {};
    },

    on(event, handler) {
        if (event == "load") {
            handler();
        }

        return () => {};
    }
};
