/* Polyfills */
// import 'core-js/features/object/entries';
// import 'core-js/features/dom-collections/for-each';

/* Vue :) */
import Vue from "vue";

/* Global Vue instance attributes */
import store from "./store/index.js";
import router from "./router/index.js";

/* i18n plugin */
import i18n from "@/i18n/plugin.js";
Vue.use(i18n);

import AppComponent from "@/components/App.vue";

import "./store/filters.js";
import "./store/components.js";

/* !!! Fastclick daña el selector de archivos de Dropzone.vue en iOS (no escoge archivos al hacer tap) */
// import Fastclick from "fastclick";
// if ("addEventListener" in document) {
// 	document.addEventListener("DOMContentLoaded", function() {
// 		Fastclick.attach(document.body);
// 	}, false);
// }

store.dispatch("wakeup")
	.then(() => {

		/* Listener global de notificaciones */
		store.state.notifications.on("notification", notification => {

			var payload =  notification.payload ? JSON.parse(notification.payload) : {};
			if( notification.hasOwnProperty('post') ||  payload.hasOwnProperty('post') )
			{
				var threadId = "";

				if(notification.hasOwnProperty('post'))
				{
					threadId = notification.post.thread;
				}
				else if(payload.hasOwnProperty('post'))
				{
					threadId = payload.post.thread;
				}

				cordova.plugins.firebase.messaging.clearNotifications();
				router.push({name: "thread", params: {threadId}});
			}

			cordova.plugins.firebase.messaging.getBadge().then(function(value) {

				cordova.plugins.firebase.messaging.setBadge(value+1);
			});
		});

		new Vue({
			el: "#app",
			store,
			router,
			render: h => h(AppComponent),

			provide() {
        return {
          $ioToken: store.state.token,
					useApiClients: store.state.apiClients
        };
      }

		});
	});

document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {

	window.open = cordova.InAppBrowser.open;

	// Intercept Android back button
	document.addEventListener("backbutton", () => {
		if (store.state.backButtonHandler) {
			let result = store.state.backButtonHandler();
			if (result === false) {
				return;
			}
		}

		if (router.currentRoute.meta.exitOnBack && !photoswipeOpen) {
			navigator.app.exitApp();
		} else {
			photoswipeOpen = false;
			router.go(-1);
		}
	}, false); 
	
	// Make inAppBrowser open external links in device browser
	document.addEventListener("click", linkClick, false);

	// Manually initialize status bar (cordova-plugin-statusbar)
	if (typeof StatusBar != "undefined") {
		StatusBar.styleDefault();
		StatusBar.overlaysWebView(false);
	}
}

// Make inAppBrowser open external links in device browser
function linkClick(e) {
	var e = window.e || e;
	var target = e.target;

	if (target.parentNode && target.parentNode.tagName == "A") {
		target = target.parentNode;
	}

	if (target.tagName !== "A" || target.href.substring(0, 4) != "http") {
		return;
	}
	e.preventDefault();
	e.stopPropagation();
	// cordova.InAppBrowser.open(target.href, "_system");
	window.open(target.href, "_system");
}

/* serviceWoker registration */
if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("service-worker.js").then(registration => {
		// Registration was successful
		// console.log("ServiceWorker registration successful with scope: ", registration.scope);
	}).catch(err => {
		// registration failed :(
		// console.log("ServiceWorker registration failed: ", err);
	});
}