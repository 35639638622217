export default {
	"common.Accept": "Accept",
	"common.Add": "Add",
	"common.Cancel": "Cancel",
	"common.Close": "Close",
	"common.Delete": "Delete",
	"common.Save": "Save",

	"common.accept": "accept",
	"common.add": "add",
	"common.cancel": "cancel",
	"common.close": "close",
	"common.delete": "delete",
  "common.save": "save",

	"action.accept": "Accept",
	"action.add": "Add",
	"action.addCheckpoint": "Add checkpoint",
	"action.addCondition": "Add condition",
	"action.addNewRule": "Add new rule",
	"action.addToCalendar": "Add to calendar",
	"action.allowReplies": "Allow replies",
	"action.allowRepliesAll": "Allow responses to all",
	"action.allowForward": "Allow forward",
	"action.addVehicle": "Add route",
	"action.archive": "Archive",
	"action.attach": "Attach",
	"action.cancel": "Cancel",
	"action.clearCache": "Clear cache",
	"action.close": "close",
	"action.createNewType": "create new type",
	"action.delete": "Delete",
	"action.deselect": "deselect",
	"action.enabled": "Enable",
	"action.Download": "Download",
	"action.form.addField": "add field",
	"action.form.RemoveField": "Delete this field",
	"action.Forward": "Forward",
	"action.forward": "forward",
	"action.HideRecipients": "Hide recipients",
	"action.highlightPost": "Add to billboard",
	"action.logout": "Logout",
	"action.markRead": "Mark read",
	"action.markUnread": "Mark unread",
	"action.notify": "Notify",
	"action.pay": "Pay",
	"action.post.addAttachment": "Add attachment",
	"action.redact": "Compose",
	"action.repeatEvery": "repeat every",
	"action.repeatOn": "repeat on",
	"action.reply": "Reply",
	"action.replyAll": "Reply all",
	"action.restore": "Restore",
	"action.schedulePublishDate": "Schedule publish date",
	"action.scheduleExpirationDate": "Schedule expiration date",
	"action.save": "Save",
	"action.select": "select",
	"action.send": "Send",
	"action.sendAnswers": "Send answers",
    "action.sendAnswer": "Send answer",
	"action.Share": "Share",
	"action.slideGps" : "Slide to start GPS",
	"action.undo": "Undo",
	"action.tapHereToUploadFiles":"Tap here to upload files",
	"adj.allDay": "all day",
	"adj.Appreciation.expelled": "expelled",
	"adj.Appreciation.mustRepeat": "Must repeat",
	"adj.Appreciation.promoted": "promoted",
	"adj.Appreciation.toDefine": "to define",
	"adj.ends": "ends",
	"adj.everyDay": "every day",
	"adj.everyMonth": "every month",
	"adj.everyWeek": "every week",
	"adj.everyYear": "every year",
	"adj.notIssued.plural": "Not issued",
	"adj.read.plural": "Read",
	"adj.repeats": "repeats",
	"adj.selected": "selected",
	"adj.statusActive": "Active",
	"adj.statusGraduated": "Graduated",
	"adj.statusInscribed": "Inscribed",
	"adj.statusPending": "Pending",
	"adj.statusSuspended": "Suspended",
	"adj.statusWithdrawn": "Withdrawn",
	"adj.unread.plural": "Unread",
	"adj.unread.singular": "Unread",
	"brother": "brother",
	"component.phi.debit.expirationDate" : "Expires",
	"component.phi.debit.interests" : "Interests",
	"component.phi.debit.issueDate" : "Issued",
	"compose.placeholder": "Say something ...",
	"condition.attributes.between": "between",
	"condition.attributes.contains": "contains",
	"condition.attributes.document": "Document",
	"condition.attributes.equalTo": "equal to",
	"condition.attributes.firstName": "First name",
	"condition.attributes.gender": "Gender",
	"condition.attributes.greaterThan": "greater than",
	"condition.attributes.lastName": "Last Name",
	"condition.attributes.lessThan": "less than",
	"condition.attributes.startsWith": "starts with",
	"employee": "employee",
	"everyone": "everyone",
	"father": "Father of",
	"group director": "Group director",
	"here": "here",
	"I did not find anyone": "I did not find anyone",
	"launcher.Website": "Website",
	"launcher.CallSchool": "Call School",
	"launcher.Location": "Location",
	"member": "Member of",
	"more people": "more people",
	"mother": "Mother of",
	"nav.calendar": "Calendar",
	"nav.contacts": "Contacts",
	"nav.highlights": "Highlights",
	"nav.inbox": "Inbox",
	"nav.school": "More",
	"no": "No",
	"noone": "no one",
	"notice.loading": "Loading",
	"notice.noPeopleSelected": "You must select destinataries",
	"notice.readByReadOfTotal": "read by {{read}} of {{total}}",
	"notice.thereIsNothingHere": "There is nothing here",
	"notice.toMe": "to me",
	"notice.ToNPeople": "To {{n}} people",
	"notice.ToOnePerson": "To one person",
	"notice.tuCompra" : "you are returning from an online transaction, please give the system a few minutes to reflect the outcome",

	"notice.geolocalizationNotSupported": "Geolocalization is not supported in this browser",
	"notice.permissionDenied": "Permission denied",
	"notice.positionNotAvailable": "Position is not available",
	"notice.establishingYourPosition": "Establishing your current position",
	"notice.generalFailure": "General failure",
	"notice.gpsSuccessfullEnable": "GPS enable",
	"notice.gpsEnable": "Enable GPS",
	"notice.gpsDisable": "Disable GPS",
	"notice.gpsLocationAccuracy": "Please turn loccation on (GPS) to improve your position",
	"notice.errorLocationAccuracy": "Can not request location accuracy",
	"notice.userDeniedPermissionLocation": "User has denied permission to use location service",
	"notice.enableLocalizationToGetPosition": "Determine your position",
	"notice.starting a": "Starting a",
	"notice.routeFinish": "Finish",

	"noun.academicYear": "Year",
	"noun.administration": "Administration",
	"noun.agenda": "Agenda",
	"noun.all.plural": "All",
	"noun.archived.plural": "Archive",
	"noun.Balance": "Balance",
	"noun.bcc": "Bcc",
    "noun.busy": "busy",
	"noun.billing": "Billing",
	"noun.calendar": "Calendar",
	"noun.checkpoints": "Checkpoints",
	"noun.Checked": "Checked",
	"noun.Unchecked": "Unchecked",
	"noun.conceptsToPay": "Items to pay",
	"noun.dashboard": "Dashboard",
	"noun.day": "Day",
	"noun.dayOfMonth": "day of month",
	"noun.dayOfWeek": "day of week",
	"noun.days": "days",
	"noun.defaultValue": "Default",
	"noun.document": "Document",
	"noun.editable": "Editable",
	"noun.Employees": "Employees",
	"noun.email":"E-mail",
	"noun.addEmail": "Add E-mail",
	"noun.deleteEmail": "Do you want to delete this email and all your notification preferences?",
	"noun.validEmail": "The email does not have a valid format",
	"noun.files" : "Files",
	"noun.form": "Form",
	"noun.form.description": "description",
	"noun.form.description": "description",
	"noun.form.options": "options",
	"noun.form.title": "title",
	"noun.form.typeCheckbox": "checkbox",
	"noun.form.typeSelect": "select",
	"noun.form.typeText": "text",
	"noun.form.typeTextarea": "textarea",
	"noun.general": "General",
	"noun.groups": "Groups",
	"noun.googleDrive": "Google Drive",
	"noun.HTMLText": "HTML Text",
	"noun.inbox": "Inbox",
	"noun.language": "Language",
	"noun.Mentions": "Mentions",
	"noun.map" : "Map",
	"noun.modality": "Modality",
	"noun.month": "Month",
	"noun.months": "months",
	"noun.mySubscriptions": "My subscriptions",
	"noun.mobileApp":"mobile App",
	"noun.name": "Name",
	"noun.namePlural": "Plural",
	"noun.nameSingular": "Singular",
	"noun.never": "never",
	"noun.none": "None",
	"noun.notifications": "Notifications",
	"noun.nounPersonFemale": "Female",
	"noun.nounPersonMale": "Male",
	"noun.payedConcepts": "Payed items",
	"noun.paymentsToApply": "Payments to apply",
	"noun.pendingCharges": "Pending invoices",
	"noun.People": "People",
	"noun.post.preview": "preview",
	"noun.post.modifyFiles": "modify files",
	"noun.post.modifyPicture": "modify picture",
	"noun.post.delete": "delete",
	"noun.post.edit": "edit",
	"noun.post.editVideo": "Edit video",
	"noun.post.selectDocument": "select document",
	"noun.post.Title": "Subject",
	"noun.postTypes": "Post types",
	"noun.prices": "prices",
	"noun.recentPayments": "Recent payments",
	"noun.Relatives": "Relatives",
	"noun.righNow": "righ now",
	"noun.section": "Section",
	"noun.sent.plural": "Sent",
	"noun.sentTo.singular": "Sent to",
	"noun.settings": "Settings",
	"noun.status": "Status",
	"noun.studentCode": "Code",
	"noun.Students": "Students",
	"noun.to": "To",
	"noun.targets": "Routes",
	"noun.tracker": "Tracker",
	"noun.transport": "Transport",
	"noun.trash": "Trash",
	"noun.type": "Type",
	"noun.urlVideo": "youtube or vimeo URL",
	"noun.urlVideoError": "This is not a valid video URL",
	"noun.value": "value",
	"noun.view": "View",
	"noun.video": "Video",
	"noun.week": "Week",
	"noun.weeks": "weeks",
	"noun.years": "years",
	"OK": "OK",
	"person.condition.attributes":               "Person details",
	"person.condition.debtor":                   "Debtor",
	"person.condition.enrollment":               "Enrollment",
	"person.condition.type":                     "Type",
	"person.condition.v3Appreciation":           "Enrollment outcome",
	"person.condition.v3ChildrenWithEnrollment": "Children in enrollment",
	"person.condition.v3Class":                  "Class",
	"person.condition.v3Debit":                  "Billing",
	"person.condition.v3DebitPrice":             "Billing by price",
	"person.condition.v3EmployeeGroups":         "Employee Group",
	"person.condition.v3FamilyWithXChildren":    "In families with N children",
	"person.condition.V3Mentions":               "Mentions",
	"person.condition.v3Role":                   "Role",
	"person.condition.v3Transport":              "Transport",
	"person.condition.V3EnrollmentModality": 	 "Modality",
	"pick prices": "pick prices",
	"post.noun.everything": "everything",
	"post.settings.highlightOff" : "Not shown in dashboard",
	"post.settings.highlightOn" : "Shown in dashboard",
	"post.settings.notificationsOff" : "Notifications disabled",
	"post.settings.notificationsOn" : "Notifications enabled",
	"post.settings.recipientsInvisible" : "Recipients hidden",
	"post.settings.recipientsVisible" : "Recipients shown",
	"post.settings.repliesOff" : "Replies disabled",
	"post.settings.repliesOn" : "Replies enabled",
	"post.settings.repliesAllOn" : "Answers for all enabled",
	"post.settings.repliesAllOff" : "Answers for all disabled",
	"post.settings.forwardOn" : "Forward enabled",
	"post.settings.forwardOff" : "Forward disabled",
	"prep.until": "until",
	"question.deleteThisEvent": "Delete this event ?",
	"question.deleteThisRule": "Delete this rule ?",
	"question.deleteThisFile": "Delete this files ?",
	"question.discardThisDraft": "Discard this draft ?",
	"question.deleteThisVideo": "Delete this video ?",
	"question.deleteThisText": "¿Delete this text ?",
	"question.deleteThisForm": "Delete this form ?",
	"search": "search",
	"sister": "sister",
	"teacher": "Teacher",
	"substitute teacher": "Substitute Teacher",
	"Total": "Total",
	"try again": "try again",
	"type.Visible": "Shown in lists",
	"type.Invisible": "Hidden from lists",
	"yes": "Yes",
    "adj.statusInactive" : "Inactive",
    "atendant": "atendant",
    "attendance": "Attendance",
    "aunt": "aunt",
    "daughter": "daughter",
    "female cousin": "female cousin",
    "granddaughter": "granddaughter",
    "grandfather": "grandfather",
    "grandmother": "grandmother",
    "grandson": "grandson",
    "male cousin": "male cousin",
    "nephew": "nephew",
    "niece": "niece",
    "noun.employee-group" : "Collaborating Group",
    "other": "other",
    "person.condition.V3PeopleSetMemberships":   "Group (in all)",
    "relative": "relative",
    "representant": "representant",
    "responsible": "responsible",
    "searching": "searching",
    "son": "son",
    "startRoute": "Start route",
    "stepdaughter": "stepdaughter",
    "stepfather": "stepfather",
    "stepmother": "stepmother",
    "stepson": "stepson",
    "student": "student",
    "uncle": "uncle",
    "confirm.deleteFile":"Do you really want to delete this attachmen?",
    "events": "events",
    "assignments": "Assignments",
    "exams": "Exams",
	"evaluations": "evaluations",

	"register.continueToProcess": "Continue",
	"register.form.addRelative": "Add relative",
	"register.form.addStudent": "Add student",
	"register.form.birthday": "Birthday",
	"register.form.cancel": "Cancel",
	"register.form.children": "Children",
	"register.form.children2": "Children",
	"register.form.course": "Course",
	"register.form.course.pick": "-- Choose a course --",
	"register.form.email": "eMail",
	"register.form.document": "Document",
	"register.form.firstName": "First name",
	"register.form.gender.female": "Female",
	"register.form.gender.male": "Male",
	"register.form.gender": "Gender",
	"register.form.lastName": "Last name",
	"register.form.lastName2": "Second last name",
	"register.form.loading": "Registering ...",
	"register.form.submit": "Register",
	"register.form.password": "Password",
	"register.form.success.title": "Check your eMail!",
	"register.form.success.email": "We've sent a message to <strong>{{email}}</strong> with instructions to continue",
	"register.form.verifyPassword": "Verify password",
	"register.form.error.birthday.empty": "You must pick a date",
	"register.form.error.children.empty": "You must choose at least one son",
	"register.form.error.course.empty": "You must select a course",
	"register.form.error.email.empty": "You must type an eMail",
	"register.form.error.email.invalid": "Invalid address",
	"register.form.error.email.taken": "This eMail is already being used",
	"register.form.error.document.empty": "You must type a document",
	"register.form.error.firstName.empty": "You must type a name",
	"register.form.error.lastName.empty": "You must type a last name",
	"register.form.error.password.empty": "You must type a password",
	"register.form.error.password.mismatch": "Passwords do not match",

	"tracking": "Tracking",
	"transport.email": "eMail",
	"transport.sms": "SMS",
	"transport.apn": "App (iOS)",
	"transport.gcm": "App (Android)",

	"launcher.billing": "Billing",
	"launcher.messaging": "Messaging",
	"launcher.transport": "Transport",

	"downloading":"Downloading",
	"error downloading file":"Error Downloading File",

	"credits.l1": "Developed by Phidias SAS",
	"credits.l2": "for",
	"credits.l3": "All rights reserved",
	"clinic.blood_type": "Blood type",
	"clinic.weight": "Weight",
    "clinic.height": "Height",
	"clinic.medical_insurance_EPS": "Medical insurance and /or EPS",
	"noun.mobile": "Mobile",

	// terminos notificaciones singular
	"Ausencia": "Absence",
  "Aviso Urgente": "Urgent notice",
  "Boletin": "Bulletin",
  "Circular": "Circular",
  "clasificado": "classified",
  "cobro": "payment",
  "Comunicado": "Communique",
  "Documento": "Document",
  "Encuesta": "Poll",
  "Evento": "Event",
  "Mensaje": "Message",
  "Noticia": "News",
  "notificacion": "notification",
  "novedad de transporte": "transportation novelty",
  "pago": "pay",
  "proceso": "process",
  "reunion": "reunion",
  "seguimiento": "follow-up",
  "tarea": "task",

	// terminos notificaciones plural
	"Ausencias": "Absences",
	"Avisos Urgentes": "Urgent Notices",
	"Boletines": "Newsletters",
	"Circulares": "Circulars",
	"clasificados": "classifieds",
	"cobros": "charges",
	"Comunicados": "announcements",
	"Documentos": "Documents",
	"Encuestas": "Polls",
	"Eventos": "Events",
	"mensajes": "Messages",
	"Noticias": "News",
	"notificaciones": "notifications",
	"novedades de transporte": "transportation news",
	"pagos": "payments",
	"procesos": "processes",
	"reuniones": "reunions",
	"seguimientos": "follow",
	"tareas": "chores",

  "launcher.classroom": "Classroom",
	"the message is expired": "the message is expired",
	"grade": "Grade",

	"privacy policies": "Privacy policies",
	"read privacy policies": "Read privacy policies",
	"attached files": "Attached files",
	"accept privacy policies": "Accept privacy policies",
	"export to csv": "Export to csv",
}
