<template>
    <div class="phi-block-files">

        <template v-if="action != 'delete'">
            <template v-if="action == 'edit' && fullUrl">

                    <div class="phi-media-camera" v-if="devicePlatform">
                        <mu-icon @click="takePhoto(true)" class="phi-media-camera-icon" value="camera_alt" :size="35" ></mu-icon>
                        <p>{{$t('action.camera')}}</p>
                    </div>

                    <dropzone :url="fullUrl" @success="success(arguments[0], arguments[1])" @error="error(arguments[0], arguments[1], arguments[2])" class="dropzone">
                        <h1>{{$t('action.tapHereToUploadFiles')}}</h1>
                    </dropzone>

                    <div class="phi-media block-options">
                        <span class="phi-media-body"></span>
                        <mu-icon @click="setGallery(false)" class="phi-media-right" value="view_column" :color="!isGallery ? 'blue' : 'grey'"></mu-icon>
                        <mu-icon @click="setGallery(true)" class="phi-media-right" value="view_compact" :color="isGallery ? 'blue' : 'grey'"></mu-icon>
                    </div>
            </template>

            <div class="fileList" :class="{gallery: isGallery}">
                <a
                    @click.prevent="download(file)"

                    v-for="(file, index) in fileList"
                    :key="file.url"
                    class="file"
                    :href="file.url"
                    target="_blank"
                    v-show="!file.isHidden"
                >
                    <div v-if="isGallery" class="preview" @click.prevent="launch(file, index, $event)">
                        <div class="overlay" v-if="file.groupCount > 0">
                            <span>+ {{ file.groupCount }}</span>
                        </div>
                        <img v-if="file.preview" :src="file.preview" :alt="file.title" class="preview-img-item">
                        <mu-icon v-if="!file.preview" value="insert_drive_file" :size="64" class="preview-img-item" />
                    </div>

                    <div v-else class="preview">
                        <img v-if="file.thumbnail" :src="file.thumbnail" :alt="file.title" class="preview-img-item">
                        <audio v-if="file.mimetype == 'audio/x-m4a'" controls preload="none" class="preview-img-item">
                            <source :src="file.url" type="audio/mp4" />
                        </audio>
                        <mu-icon v-if="!file.thumbnail" value="insert_drive_file" :size="64" class="preview-img-item" />
                    </div>

                    <div class="description">
                        <h1 v-if="action == 'edit'" v-text="file.title" @click="rename(file)" class="editable"></h1>
                        <h1 v-else v-text="file.title"></h1>
                        <p>{{ file.size | bytes }}</p>
                    </div>

                    <div class="actions" v-if="action == 'edit'">
                        <mu-icon class="delete" value="delete" :size="18" @click.stop.prevent="remove(file)" />
                    </div>
                </a>
            </div>
        </template>

        <template v-if="action == 'delete'">
            <h1>{{$t('question.deleteThisFile')}}</h1>
            <button type="button" class="phi-button danger" @click="destroy()">{{$t('action.delete')}}</button>
            <button type="button" class="phi-button cancel" @click="$emit('reset')">{{$t('action.cancel')}}</button>
        </template>

        <mu-snackbar v-if="toastIsShown" :message="$t('error.uploadingFile')" :action="$t('action.close')" @action-click="toastIsShown = false" @close="toastIsShown = false" />
    </div>
</template>

<script>
import app from '../../../store/app.js';
import Dropzone from '../../Dropzone/Dropzone.vue';
import {download} from '/src/libraries/phidias.js/lib/File/download.js';

export default {

    phiBlock: {
		title: "files",
        icon: "attachment",
        actions: {
            edit: {
                title: "modifyFiles"
            },

            delete: {
                title: "delete"
            }
        }
    },

    name: "phi-block-files",
    components: {Dropzone},
    props: {
     post: {type: Object},
     block: {type: Object},
     action: {type: String},
     },

    computed: {
        fileList() {
            if (!this.isGallery) {
                return this.files;
            }

            var limit = 5;

            var retval = [];
            for (var i = 0; i < limit && i < this.files.length; i++) {
                this.files[i].isHidden = false;
                retval.push(this.files[i]);
            }

            if (i > 0) {
                retval[i-1].groupCount = this.files.length - limit;
            }

            for (var j = limit; j < this.files.length; j++) {
                this.files[j].isHidden = true;
                retval.push(this.files[j]);
            }

            return retval;
        }
    },

    data() {
        return {
			files:   [],
			fullUrl: null,
            permission: false,
            photoswipeItems: [],
            typeFile: 'file',
            photoswipeOptions: {
                bgOpacity: 0.97,
                shareEl: false,
                fullscreenEl: false,
                downloadEl: true,
                closeOnScroll: false,
                isClickableElement: function(el) {
                    return el.tagName === 'A' || el.classList.contains('pdf');
                },
                customDownloadFunction: function(pswpItem) {
                    this.download(pswpItem.fileDetails);
                }.bind(this)
			},
			dzError: false,
			dzErrorMessage: null,
            toastIsShown: false,
            isGallery: !!this.block.options,
            devicePlatform: false,
            Photo: ""
        }
    },

    methods: {
        setGallery(bool) {
            this.isGallery = bool;
            this.block.options = bool ? 1 : null;
            this.$emit('change');
        },

        /* launch click event depending on the type of file */
        launch(file, index, event) {
            if (!this.isGallery) {
                return;
            }

            event.stopPropagation();
            this.$photoswipe.open(index, this.photoswipeItems, this.photoswipeOptions)
        },

        takePhoto()
        {
            navigator.camera.getPicture(this.cameraSuccess, this.cameraError,
            {
                quality: 75,
                allowEdit : false,
                correctOrientation: true,
                destinationType: Camera.DestinationType.FILE_URI,
                sourceType: Camera.PictureSourceType.CAMERA,
                encodingType: Camera.EncodingType.JPEG,
                mediaType: Camera.MediaType.PICTURE,
                saveToPhotoAlbum: false
            });
        },

        async cameraSuccess(imageDATA)
        {
            let that = this;
            var boundary = Math.random().toString().substr(2);

            // get file entry
            const imgFileEntry = await this.getFileEntry(imageDATA);

            imgFileEntry.file(function (file)
            {
                var reader = new FileReader();
                reader.onloadend = function ()
                {
                    //message for start upload
                    cordova.plugin.pDialog.init({progressStyle: 'HORIZONTAL', title: that.$t('uploading'), message: file.name});
                    
                    // This blob object can be saved to firebase
                    var blob = new Blob([new Uint8Array(this.result)], { type: "image/jpeg" });


                    var formData = new FormData();
                    formData.append("cameraFile", blob, file.name);

                    var oReq = new XMLHttpRequest();
                    oReq.open("POST", that.fullUrl, true);

                    oReq.onprogress = function(evt)
                    {
                        if (evt.lengthComputable)
                        {
                            var percentComplete = (evt.loaded / evt.total) * 100;
                            cordova.plugin.pDialog.setProgress(percentComplete);                            
                        }
                    }

                    oReq.onloadend = function (oEvent) 
                    {
                        cordova.plugin.pDialog.dismiss();
                        that.reload();
                    };
                    // Pass the blob in to XHR's send method
                    oReq.send(formData);

                };
                reader.readAsArrayBuffer(file);
            });
        },

        cameraError(message)
        {
            alert('Failed because: ' + message);
        },

        getFileEntry (fileURL) {
            return new Promise((resolve, reject) =>
            {
                window.resolveLocalFileSystemURL(
                    fileURL,
                    fileEntry => {
                        resolve(fileEntry)
                    },
                    err => {
                        reject(err)
                    }
                )
            });
        },

		/* The response from the API is an array of uploaded files */
		success(file, response) {
            //console.log("sucess:", file, response);
            if(response.length == 0){
                //this.toastIsShown = true;
                console.log("respuesta cero");
            }
            this.files = response.concat(this.files);
            this.redraw();
		},

		error(file, errorMessage, xhr) {
			//ZZZZzzzzz Some work to do ...
            this.toastIsShown = true;
            console.log("funcion error: ", errorMessage, xhr);
		},

        reload() {
            app.api
                .get(this.block.url)
				.then(files => this.files = files)
                .then(() => this.redraw());
        },

        destroy() {
            let errorFlag = false;
            this.files.forEach( file =>{
                app.api.delete(file.endpoint + "/" + file.path)
                .catch((err) => {
                    errorFlag = true;
                    throw BreakException;
                });
            });

            if(!errorFlag)
            {
                this.$emit("destroy");
            }
        },

        rename(file) {
            var newTitle = prompt("", file.title);
            newTitle = newTitle ? newTitle.trim() : null;
            if (newTitle == null || newTitle == file.title) {
                return;
            }

            file.title = newTitle;
            app.api
                .put(file.endpoint + "/" + file.path, file);
        },

        remove(file) {
            if (!confirm(this.$t('confirm.deleteFile'))) {
                return false;
            }

            this.files.splice(this.files.indexOf(file), 1);
            this.redraw();
            app.api
                .delete(file.endpoint + "/" + file.path)
                .then(() => this.$emit("change"));
        },

        redraw() {
            this.photoswipeItems = [];
            this.files.forEach(file => {
                if (file.mimetype && file.mimetype.substring(0, 5) == 'image') {
                    this.photoswipeItems.push({
                        fileDetails: file,
                        src: file.preview,
                        w: 600,
                        h: 'auto',
                        title: file.title,
                        downloadUrl: typeof file.downloadUrl != 'undefined' ? file.downloadUrl : file.url
                    });
                } else if (file.mimetype && file.mimetype == 'application/pdf') {
                    if (typeof isMobile != "undefined" && isMobile) {
                        this.photoswipeItems.push({
                            fileDetails: file,
                            html: '<div class="nopreview"><h3>'+file.title+'</h3><a class="download" download href="'+file.url+'" target="_blank">' + this.$t('action.Download') + '</a></div>',
                            title: file.title,
                            downloadUrl: typeof file.downloadUrl != 'undefined' ? file.downloadUrl : file.url
                        });
                    } else {
                        this.photoswipeItems.push({
                            fileDetails: file,
                            html: '<div class="pdf"><a class="download" download href="'+file.url+'" target="_blank">' + this.$t('action.Download') + '</a><iframe src="https://docs.google.com/gview?url='+file.url+'&embedded=true" style="width:100%; height:100%;" frameborder="0"></iframe></div>',
                            title: file.title,
                            downloadUrl: typeof file.downloadUrl != 'undefined' ? file.downloadUrl : file.url
                        });
                    }
                } else {
                    this.photoswipeItems.push({
                        fileDetails: file,
                        html: '<div class="nopreview"><h3>'+file.title+'</h3><a class="download" download href="'+file.url+'" target="_blank">' + this.$t('action.Download') + '</a></div>',
                        title: file.title,
                        downloadUrl: typeof file.downloadUrl != 'undefined' ? file.downloadUrl : file.url
                    });
                }
            });
        },

        download(file)
        {
            
            let response = download(file);
            console.log(response);
        },

        errorRequest(downloadUrl) {
            return window.open(downloadUrl, '_system');
            console.error('Camera permission is not turned on');
        }
    },

    mounted() {

        if (typeof device == "undefined" || typeof device.platform == "undefined")
        {
            this.devicePlatform = false;
            return;
        }

        if (device.platform == "Android")
        {
            this.devicePlatform = true;
        }
    },

    created() {
        if (!this.block.url) {
            var random     = Math.floor((Math.random() * 100000) + 1);
            this.block.url = `posts/${this.post.id}/resources/files/block-${random}`;
            this.$emit("change");
        }

        this.fullUrl = app.api.host + "/" + this.block.url;
        this.reload();
    },

    i18n: {
        "en": {
            "error.uploadingFile": "error uploading file",
            "action.camera": "Camera",
            "uploading": "Subiendo",
        },

        "es": {
            "error.uploadingFile": "error al subir el archivo",
            "action.camera": "Camara",
            "uploading": "Subiendo",
        },

        "fr": {
            "error.uploadingFile": "erreur lors de l'envoi du fichier",
            "action.camera": "Caméra",
            "uploading": "Subiendo",
        },

        "de": {
            "error.uploadingFile": "Fehler beim Hochladen der Datei",
            "action.camera": "Kamera",
            "uploading": "Subiendo",
        },

        "it": {
            "error.uploadingFile": "errore nel caricamento del file",
            "action.camera": "Fotocamera",
            "uploading": "Subiendo",
        }
    },
}
</script>

<style scoped lang="scss">
.phi-block-files {
    position: relative;
    overflow-x: auto;
    // max-height: 500px;

    .fileList {
        display: flex;
    }

    .file {
        background-color: transparent;
        margin: 0 0.2em;
        padding: 8px;

        .preview {
            width: 100px;
            height: 100px;
            overflow: hidden;
            margin: auto;
            text-align: center;

            cursor: pointer;

            & > * {
                width: 100%;
            }

            .mu-icon {
                margin-top: 16px;
            }
        }

        .description {
            margin-top: 12px;

            h1 {
                font-size: 1em;
                max-width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.editable:hover {
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, .05);
                }
            }

            p {
                font-size: 12px;
                color: #666;
                cursor: default;
            }
        }

        .actions {
            color: #777;
            margin-top: 6px;
            text-align: right;
            padding: 3px 6px;

            .delete {
                cursor: pointer;
                &:hover {
                    color: #900;
                }
            }
        }

    }

    .phi-media-figure {
        white-space: nowrap;
        width: 80px;

        p {
            margin: 0;
            padding-top: 5px;
            text-align: center;
            font-size: 0.8em;
            font-weight: 300;
        }
    }

    .dropzone {
        border: 3px dotted #ccc;
        width: 100%;

        h1 {
            font-size: 1.2em;
            color: #444;
        }
    }

    .gallery {
        flex-wrap: wrap;
        justify-content: space-around;

        .file {
            margin: 0;
            padding: 0;
            border: 1px solid #fff;
            background-color: #000;

            position: relative;

            flex: 1;

            &:first-child,
            &:nth-child(2) {
                flex: 0 0 50%;
            }

            .preview {
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background-color: rgba(0, 0, 0, .7);

                text-align: center;
                display: flex;
                align-items: center;

                span {
                    display: block;
                    flex: 1;
                    text-align: center;
                    color: #fff;
                    font-size: 28px;
                    opacity: .89
                }
            }

        }

        .description {
            display: none;
        }
    }

    .block-options {
        .phi-media-right {
            cursor: pointer;
        }
    }

    .ui-dialog-container
    {
        text-align : center;
    }

    .phi-media-camera
    {
        
        width: 100px; 
        text-align: center; 
        margin-bottom: 12px;   

        p{
            font-size: 12px;
        }  
    }

    .phi-media-camera-icon
    {
        margin-bottom: 0px;
    }
}
</style>

<style lang="scss">
.pswp {
    .pdf {
        width: 80%;
        height: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .nopreview {
        width: 80%;
        height: 80%;
        margin: auto;

        margin-top: 25%;
        color: #fff;
        text-align: center;
    }

    a.download {
        display: block;
        margin-top: 32px;
        margin-bottom: 12px;
        font-size: 20px;
        border-radius: 6px;
        padding: 12px;
        background-color: rgba(255, 255, 255, 0.15);
        text-align: center;
        color: #fff;
    }

    .pswp__img {
        height: auto !important;
    }
}

.dropzone {
    .dz-message {
        margin: 1em 0;
    }
}
</style>


<style lang="scss">
.pswp__button--download {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFQjg1RkFCRDEzQTExRTc5MUM2OUY0QUMzQ0NDRDJGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFQjg1RkFDRDEzQTExRTc5MUM2OUY0QUMzQ0NDRDJGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUVCODVGQTlEMTNBMTFFNzkxQzY5RjRBQzNDQ0NEMkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUVCODVGQUFEMTNBMTFFNzkxQzY5RjRBQzNDQ0NEMkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7j+G93AAABTUlEQVR42tzUvUoDQRDA8dvEQnOCX1ilEbQQsdVC0vkSVnY+Rip7HyC9jWBjZyN2gtjYCxGFEMEcATUYE2/9T5iDZd2Ld60LP1j2dmbvmJ0z1tooMFZxjSW4GwbYx5MfMBOFh0EdC976Z15MJSeRvMVXYH2EtEyi0uN/JDJlEr17ZXeL8BYKyEq5rl61KjGqOQdvo6/zFbTxEMmFxA4u0Ucbzxjb3yPVZ7InwRX2YLJEBps4t8XHBbZQmXSHJsrMoonBlARDHCN2Y/1EmQN0AklecKhfEBVJJHZx4yS5QyNvfzaphk5BHac4w1rgudHYSCYnlK+GR7SQeCWf01J/eOvyZzjCxuT34pT5HotTPjVUmFuNHcuF7Oq/R65+Q9/I/NEmcsPnnc7oyqf1mCzrQ2mN7wI9mOrNj3VvIm/UwdBpGVOweUd6sIzejwADAFsy29vL5QCBAAAAAElFTkSuQmCC') no-repeat 50% 50% !important;
}
</style>
