<template>
    <div class="phi-block-html">
        <template v-if="!action">
            <div v-html="body"></div>
        </template>

        <template v-if="action == 'edit'">
            <tiny-mce v-model="body" @input="save()"></tiny-mce>
        </template>

        <template v-if="action == 'delete'">
            <h1>{{$t('question.deleteThisText')}}</h1>
            <button type="button" class="phi-button danger" @click="destroy()">{{$t('action.delete')}}</button>
            <button type="button" class="phi-button cancel" @click="$emit('reset')">{{$t('action.cancel')}}</button>
        </template>
    </div>
</template>

<script>
import TinyMce from '../../TinyMce.vue';
import app from '../../../store/app.js';

export default {

    phiBlock: {
        title: "HTMLText",
        icon: "text_format",
        actions: {
            edit: {
                title: "edit"
            },
            delete: {
                title: "delete"
            }
        }
    },

    name: "phi-block-html",
    components: {TinyMce},
    props: ["block", "action"],

    data() {
        return {
            body: null,
            timer: null
        }
    },

    created() {
        if (!this.block.url) {
            app.api.post("/media/html", {body: ""})
                .then(html => {
                    this.block.url = "media/html/" + html.id;
                    this.$emit("change");
                });
        } else {
            this.reload();
        }
    },

    methods: {
        reload() {
            app.api.get(this.block.url)
                .then((response) => {
                    this.body = response.body;
                });
        },

        destroy() {
            app.api.delete(this.block.url);
            this.$emit("destroy"); // don't wait for api response. just destroy the block
        },

        save() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                app.api.put(this.block.url, {body: this.body});
            }, 500);
        }
    }
}
</script>

<style lang="scss">
.phi-block-html {
    // Prevent floating images from leaking outside the element's container
    overflow-y: auto;
    word-wrap: break-word;
}

.phi-block-html img {
    // Prevent images width to alter text display
    display: block;
    max-width: 100%;
    height: auto;
}
</style>
