import i18next from 'i18next';
import localforage from 'localforage';

/* Flatpickr locales */
import Flatpickr from 'flatpickr';
import flatpickrEs from 'flatpickr/dist/l10n/es.js';
import flatpickrDe from 'flatpickr/dist/l10n/de.js';
import flatpickrFr from 'flatpickr/dist/l10n/fr.js';
let flatpickrLocales = {
    en: Flatpickr.l10ns.en,
    es: flatpickrEs.es,
    de: flatpickrDe.de,
    fr: flatpickrFr.fr
};

/* date-fns */
import { de, es, fr } from 'date-fns/locale'
const dateFnsLocales = { de, es, fr };

/* Nuevo modulo (!!!) */
import i18n from '@/modules/i18n/singleton';

export default {
    state: {
        language: "en",
        terms: null,

        available: {
            "es": "Espa\u00f1ol",
            // "es-CO": "Espa\u00f1ol (CO)",
            "en": "English",
            "de": "Deutsch",
            "fr": "Fran\u00e7ais",
            "it": "Italiano"
        },

        dateFns: {
            locale: null
        }
    },

    getters: {
        isCurrentLanguage(state) {
            return language => {
                return state.language.indexOf(language) === 0;
            };
        },

        usesAmPm(state) {
            let date = new Date(Date.UTC(2012, 11, 12, 3, 0, 0));
            let dateString = date.toLocaleTimeString(state.language);

            // https://stackoverflow.com/questions/27647918/detect-with-javascript-if-users-machine-is-using-12-hour-clock-am-pm-or-24-cl
            return (dateString.match(/am|pm/i) || date.toString().match(/am|pm/i) );
        }
    },

    mutations: {
        setLanguage(state, language) {
            state.language = language;
        },

        setTerms(state, {language, terms}) {
            if (state.terms == null) {
                state.terms = {};
            }
            state.terms[language] = terms;
        },

        setDateFns(state, obj) {
            state.dateFns = obj;
        }
    },

    actions: {
        setLanguage(context, language) {
            language = language.replace('_', '-');
            let baseLanguage = language.split('-')[0];


            /* Update the state */
            context.commit("setLanguage", language);

            /* Look for dictionary */
            context.dispatch("fetchDictionary");

            /* Localize external libraries */
            /* date-fns */
            let dateFnsLocale = dateFnsLocales[language];
            if (!dateFnsLocale) {
                dateFnsLocale = dateFnsLocales[baseLanguage];
            }
            context.commit("setDateFns", {locale: dateFnsLocale});

            /* i18next */
            i18next.changeLanguage(language);

            /* Flatpickr */
            let flatpickrLocale = flatpickrLocales[language];
            if (!flatpickrLocale) {
                flatpickrLocale = flatpickrLocales[baseLanguage];
            }
            Flatpickr.localize(flatpickrLocale);
        },

        setTerms(context, {language, terms}) {
            context.commit("setTerms", {language, terms});

            /* update i18next dictionary */

            /*
            i18next.$appendLanguage es una funcion que se declara en
            el PLUGIN src\i18n\plugin.js.  Si el plugin no se instala esto no funciona
            */
            if (typeof i18next.$appendLanguage != "undefined") {
                i18next.$appendLanguage(language, terms);
            }
        },

        async fetchDictionary(context) {
            if (!context.rootState.api) {
                return;
            }

            let language = context.state.language;

            let storedTerms = await localforage.getItem(`phidias.dictionary.${language}`);
            if (storedTerms) {
                context.dispatch("setTerms", {language, terms: storedTerms});
            }


            /* Compatibilidad con nuevo modulo i18n (!!!)  */
            i18n.setLanguage(language);
            context.rootState.api.get(`/1/i18n/languages/${language}`)
                .then(terms => {
                    let incomingLang = {};
                    incomingLang[language] = terms;
                    i18n.override(incomingLang);
                });

            return context.rootState.api.get(`/vue/i18n/languages/${language}`)
                .then(terms => {
                    context.dispatch("setTerms", {language, terms});
                    localforage.setItem(`phidias.dictionary.${language}`, terms);
                })
                .catch(() => {});  // fail silently
        }
    }
}