<template>
    <div class="phi-block-form" v-if="form">
        <template v-if="!action">
            <div class="entity">
                <h3 v-text="form.description"></h3>

                <div class="records" v-if="records.length">
                    <div class="record" v-for="record in records">

                        <div class="fields">
                            <div class="field" v-for="field in form.fields">
                                <label v-text="field.title"></label>
                                <div v-if="field.type == 'checkbox'" class="value" v-text="record.values[field.name] == 1 ? $t('noun.Checked') : $t('noun.Unchecked')"></div>
                                <div v-if="field.type != 'checkbox'" class="value" v-text="record.values[field.name]"></div>
                            </div>
                        </div>

                        <ui-item
                            v-if="isSuccess" 
                            class="message-item" 
                            icon="g:check_circle" 
                            icon-color="#4F8A10"
                            :text="$t('status.reply.success')"
                        ></ui-item>
                    </div>
                </div>

                <div class="form" v-if="!records.length">
                    <div class="fields">
                        <div class="field" v-for="field in form.fields" :key="field.id">
                            <div v-if="field.type == 'text'">
                                <label v-text="field.title"></label>
                                <input type="text" v-model="newRecord[field.name]">
                            </div>
                            <div v-if="field.type == 'textarea'">
                                <label v-text="field.title"></label>
                                <textarea v-model="newRecord[field.name]"></textarea>
                            </div>
                            <div v-if="field.type == 'select'">
                                <label v-text="field.title"></label>
                                <select v-model="newRecord[field.name]">
                                    <option :value="null">---</option>
                                    <option v-for="option in getOptions(field)" v-text="option" :key="option"></option>
                                </select>
                                <span class="required">*</span>
                            </div>
                            <div v-if="field.type == 'checkbox'">
                                <label :for="'chbox-' + field.id" v-text="field.title"></label>
                                <input :id="'chbox-' + field.id" type="checkbox" v-model="newRecord[field.name]">
                            </div>
                            <span class="description" v-text="field.description"></span>
                        </div>
                    </div>

                    <footer>
                        <button type="button" :disabled="!canSubmit" @click="saveRecord()">{{ $t('action.sendAnswer') }}</button>
                    </footer>
                </div>
            </div>
        </template>

        <template v-if="action == 'edit'">
            <phi-form-editor v-model="form"></phi-form-editor>
        </template>

        <template v-if="action == 'delete'">
            <h1>{{$t('question.deleteThisForm')}}</h1>
            <button type="button" class="phi-button danger" @click="destroy()">{{$t('action.delete')}}</button>
            <button type="button" class="phi-button cancel" @click="$emit('reset')">{{$t('action.cancel')}}</button>
        </template>
    </div>
</template>

<script>
import PhiFormEditor from '../Form/Editor.vue';
import app from '../../../store/app.js';

import { UiItem } from '@/modules/ui/components';

export default {

    phiBlock: {
		title: "form",
        icon: "assignment",
        actions: {
            edit: {
                title: "edit"
            },
            delete: {
                title: "delete"
            }
        }
    },

    name: "phi-block-form",
    props: ["block", "action"],
    components: {PhiFormEditor, UiItem},

    data() {
        return {
            user:          app.user,
            form:          null,
            changeTimeout: null,

            records:    [],
            newRecord:  {},
            recordsUrl: null,
            isSuccess: false
        }
    },

    computed: {
        canSubmit() {
            /* El formulario se puede enviar cuando la persona ha diligenciado todos los campos de tipo "select" */
            if (!this.form) {
                return false;
            }

            let emptySelectors = 0;
            
            this.form.fields.forEach(field => {
                if (field.type == "select" && typeof this.newRecord !== "undefined"  && typeof this.newRecord[field.name] !== "undefined" && !this.newRecord[field.name]) {
                    emptySelectors++;
                }
            })
            return emptySelectors == 0;
        }/*,

        hasEmptyTextFields() {
            if (!this.form) {
                return false;
            }

            let emptyTexts = 0;
            this.form.fields.forEach(field => {
                if ((field.type == "text" || field.type == "textarea") && typeof this.newRecord !== "undefined"  && typeof this.newRecord[field.name] !== "undefined" && !this.newRecord[field.name]) {
                    emptyTexts++;
                }
            })
            return emptyTexts == 0;
        }*/
    },

    created() {
        if (!this.block.url) {
            app.api
                .post("/data/entities", {
                    title:       "",
                    description: "",
                    fields:      []
                })
                .then(dataEntity => {
                    this.form        = dataEntity;
                    this.form.fields = [];
                    this.block.url = "data/entities/" + dataEntity.id;
                    this.$emit("change");
                });
        } else {
            this.reload();
        }
    },

    watch: {
        form: {
            deep: true,
            handler(newValue, oldValue) {
                if (!oldValue || (!newValue.name && !newValue.value && !newValue.description && !newValue.fields && !newValue.fields.length)) {
                    return;
                }

                clearTimeout(this.changeTimeout);
                this.changeTimeout = setTimeout(() => {
                    app.api.put(this.block.url, this.form);
                }, 500);
            }
        }
    },

    methods: {
        reload() {
            app.api.get(this.block.url)
                .then(response => {
                    this.form = response;
                    this.resetNewRecord();

                    /* Get existing records */
                    if (this.user && this.user.id) {
                        this.recordsUrl = `people/${this.user.id}/data/entities/${this.form.id}/records`;
                        app.api.get(this.recordsUrl)
                            .then(records => this.records = records);
                    }
                });
        },

        destroy() {
            if (this.block.url) {
                app.api.delete(this.block.url);
            }
            this.$emit("destroy"); // don't wait for api response. just destroy the block 
        },

        getOptions(field) {
            if (field.options) 
                return field.options.split("\n");
            else 
                return [];
        },

        saveRecord() {
            this.isSuccess = false;

            if (!this.recordsUrl) {
                return;
            }

            app.api.post(this.recordsUrl, this.newRecord)
                .then(createdRecord => {
                    this.records.push(createdRecord);
                    this.resetNewRecord();
                    
                    this.isSuccess = true;
                    setTimeout(() => this.isSuccess = false, 5000);
                });
        },

        /* Set newRecord properties for VUE to track */
        resetNewRecord() {
            this.newRecord = {};
            this.form.fields.forEach(field => this.$set(this.newRecord, field.name, null));
        }
    },

    i18n: {
        "en": {
            "status.reply.success": "reply sent successfully"
        },

        "es": {
            "status.reply.success": "respuesta enviada con éxito"
        },

        "fr": {
           "status.reply.success": "réponse envoyée avec succès"
        },

        "de": {
            "status.reply.success": "Antwort erfolgreich gesendet"
        },

        "it": {
            "status.reply.success": "risposta inviata con successo"
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-block-form {
    border: 1px dashed #ccc;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    .entity {
        padding: 7px;
    }

    .form {
        button {
            display: block;
            margin: 0;
            padding: 9px 27px;
            border: 0;

            font-size: 1em;
            border-radius: 4px;
            background-color: #5091e0;
            color: #fff;

            &[disabled] {
                opacity: .5;
                background-color: #5091e0;
                color: #fff;
            }
        }
    }

}

.entity {
    & > h3 {
        color: #333;
        margin-bottom: 1em;
    }

    .field {
        margin: 0 0 32px 0;

        .description {
            font-size: 0.7em;
            color: #666;
        }

        .required {
            font-weight: bold;
            padding-left: 3px;
            color: red;
        }

        label {
            display: block;
            font-size: 0.9em;
            color: #444;
        }
    }

}

.ui-item.message-item{
    color: #4F8A10;
    background-color: #DFF2BF;
    border-radius: 5px;
}
</style>
