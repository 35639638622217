<template>
    <div class="phi-filesystem-folder">
        <section class="file-upload">
            <dropzone :url="fullUrl" @success="onUpload(arguments[0], arguments[1])" class="dropzone">
                 <h1>{{$t('action.tapHereToUploadFiles')}}</h1>
            </dropzone>
        </section>

        <section class="file-list">
            <ul>
                <li v-for="file in files">
                    <img :src="file.thumbnail" :alt="file.title" @click="select(file)" />
                    <footer>
                        <span class="title">{{ file.title }}</span>
                        <span @click="deleteFile(file)" class="eliminar">&times;</span>
                    </footer>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import Dropzone from '../../Dropzone/Dropzone.vue';

export default {
    name: "phi-filesytem-folder",

    components: {Dropzone},

    props: {
        api: {
            type: Object,
            required: true
        },

        url: {
            type: String,
            required: true
        },

        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            files: []
        }
    },

    computed: {
        fullUrl() {
            return this.api.host + '/' + this.url;
        }
    },

    methods: {
        reload() {
			this.api.get(this.url)
				.then(files => this.files = files)
        },

		onUpload(file, response) {
            this.files = response.concat(this.files);
        },

        select(file) {
            this.$emit("select", file);
        },

        deleteFile(file) {
            file.deleteDate = 1;

            this.api.put(this.url + "/" + file.path, file)
                .then(response => {
                    this.reload();
                })
        }
    },

    mounted() {
        this.reload();
    }
}
</script>


<style lang="scss">
.phi-filesystem-folder {

    .file-list {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            display: flex;
            flex-wrap: wrap;
            align-items: baseline;

            li {
                cursor: pointer;

                width: 180px;
                height: 140px;

                margin: 0.5em;
                text-align: center;

                img {
                    max-width: 100%;
                    max-height: 100%;

                    transition: all 170ms;
                }

                &:hover {
                    img {
                        transform: scale(1.02);
                        box-shadow: 0 2px 5px #888888;
                    }
                }

                footer {
                    font-size: 10px;
                    text-align: center;
                    .eliminar {
                        color: red;
                        margin-right: 10px;
                        margin-left: 7px;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .file-upload .vue-dropzone {
        background-color: transparent;
        border: 2px dashed #ccc;
        border-radius: 8px;

        .dz-message {
            margin: 0;

            h1 {
                padding: 24px 12px;
                font-size: 1.2em;
                color: #777;
            }
        }
    }

}
</style>
