export default {
    push: null,
	listeners: [],

	onNotification(listener) {
        this.listeners.push(listener);
	},

    subscribe() {
        return new Promise((resolve, reject) => {

            let that = this;  
            var permissions = cordova.plugins.permissions;  
            cordova.plugins.firebase.messaging.setBadge(0);

            if(window.device.platform == 'Android')
            {
                permissions.checkPermission(permissions.POST_NOTIFICATIONS, function (status) {
                    if (!status.hasPermission) {
                       
                        permissions.requestPermission(permissions.POST_NOTIFICATIONS, function (st) {
                            if (st.hasPermission) {

                                cordova.plugins.firebase.messaging.getToken().then(function(token) {
                                    // console.log("token APK: ", token)
                                    let device = {
                                            token:    token,
                                            platform: 'gcm',
                                            model:    window.device.model,
                                            uuid:     window.device.uuid
                                        }
                                    resolve(device);
                                });
                                
                            } else {
                                reject();
                            }
                        });
                    }
                });       
            }
            else
            {
                cordova.plugins.diagnostic.requestRemoteNotificationsAuthorization({
                    successCallback: function(){

                        let device = {};
                        console.log("Successfully requested remote notifications authorization");

                        cordova.plugins.firebase.messaging.onTokenRefresh(function() {
                            console.log("Device token updated");
                        });

                        cordova.plugins.firebase.messaging.getToken("apns-string").then(function(token) {

                            console.log("token iOS con firebase messaging: ", token)
                            device = {
                                token:    token,
                                platform: window.device.platform,
                                model:    window.device.model,
                                uuid:     window.device.uuid
                            }
                            
                        });

                        resolve(device);
                    },
                    errorCallback: function(err){
                       console.error("Error requesting remote notifications authorization: " + err);
                    },
                    types: [
                        cordova.plugins.diagnostic.remoteNotificationType.ALERT,
                        cordova.plugins.diagnostic.remoteNotificationType.SOUND,
                        cordova.plugins.diagnostic.remoteNotificationType.BADGE
                    ],
                    omitRegistration: false
                }); 
            }

            //Mesage in foreground
            cordova.plugins.firebase.messaging.onMessage(function(payload) {});

            //mesage in background
			cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {

				that.listeners.forEach(listener => listener(payload));
			});
        });
    },

    unsubscribe() {
        return new Promise((resolve, reject) => {
            // this.push.unregister(resolve, reject);
            cordova.plugins.firebase.messaging.deleteToken().then(function() {
                console.log("Token revoked successfully")
                resolve;
            });
        });
    }
}