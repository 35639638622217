import Vue from "vue";

export default {
    state: {
        tabs: [],
        currentTabId: null
    },

    getters: {
        currentTab(state) {
            return state.tabs.find(t => t.id == state.currentTabId);
        }
    },

    mutations: {
        setCurrentTab(state, tab) {
            state.currentTabId = tab.id;
        },

        pushTab(state, tab) {
            let index = state.tabs.findIndex(t => t.id == tab.id);
            if (index == -1) {
                return state.tabs.push(tab);
            }

            // override existing tab, if any
            Vue.set(state.tabs, index, tab);
        },

        closeTab(state, tabId) {
            let index = state.tabs.findIndex(t => t.id == tabId);
            if (index >= 0) {
                state.tabs.splice(index, 1);
            }
        },

        setCurrentTabTitle(state, title) {
            if (!state.currentTabId) {
                return;
            }

            let index = state.tabs.findIndex(t => t.id == state.currentTabId);
            if (index >= 0) {
                Vue.set(state.tabs[index], 'title', title);
            }
        }
    },

    actions: {
        async hitRoute(context, route) {
            // Look for a "tab" property in the matched route meta object
            let found = route.matched.find(rt => rt.meta && rt.meta.tab);
            if (!found) {
                return;
            }

            let tabId = found.meta.tab;
            if (typeof tabId == "function") {
                tabId = tabId(route.params);
            }

            let tab = await context.dispatch("openTab", {
                id: tabId,
                closable: true,

                // Store the current route in the tab's default target
                // target: route
                target: {
                    name: route.name,
                    params: route.params
                }
            });

            context.commit("setCurrentTab", tab);
            return tab;
        },

        async openTab(context, tab) {
            if (typeof tab == "string") {
                tab = {id:tab};
            }

            let found = context.state.tabs.find(t => t.id == tab.id);
            if (found) {
                // Closable option is never overwritten to true.
                let initialClosable = found.closable && tab.closable;
                found = Object.assign(found, tab);
                found.closable = initialClosable;

                return found;
            }

            // Put a placeholder tab while we wait
            tab.placeholder = true;
            context.commit("pushTab", tab);

            let newTab = await context.dispatch("buildTab", tab.id);
            newTab = Object.assign(tab, newTab);
            newTab.placeholder = false;

            context.commit("pushTab", newTab);

            return newTab;
        },

        async buildTab(context, tabId) {
            if (tabId == "admin") {
                return {
                    id: "admin",
                    title: "Administración",
                    target: {
                        name: "PageAdmin"
                    },
                    menu: [
                        {
                            title: "Inicio",
                            to: {
                                name: "PageAdminHome"
                            }
                        },

                        {
                            title: "Extraescolares",
                            to: {
                                name: "PageAdminExtracurricular"
                            }
                        },

                        {
                            title: "Contenidos",
                            children: [
                                {
                                    title: "Dashboard",
                                    to: {
                                        name: "BlockEditor",
                                        params: {blockId: "dashboard"}
                                    }
                                },

                                {
                                    title: "Test",
                                    to: {
                                        name: "BlockEditor",
                                        params: {blockId: "test"}
                                    }
                                }
                            ]

                        }
                    ]
                };
            }

            let parts = tabId.split(":");
            if (parts.length > 1) {
                let type = parts[0];

                switch (type) {
                    case "person":
                        let personId = parts[1];
                        return context.dispatch("buildPersonTab", personId);
                }
            }

        },

        async buildPersonTab(context, personId) {
            let person = await context.rootState.api.get(`/people/${personId}`);

            return {
                id: `person:${person.id}`,
                title: `${person.firstName} ${person.lastName}`,
                data: {
                    person
                },
                target: {
                    name: "PagePerson",
                    params: {
                        personId: person.id
                    }
                },
                menu: [
                    {
                        title: "Dashboard",
                        to: {
                            name: "PagePersonBlock",
                            params: {
                                personId: person.id,
                                blockId: 'dashboard'
                            }
                        }
                    },

                    {
                        title: "Anuncios",
                        to: {
                            name: "PagePersonBlock",
                            params: {
                                personId: person.id,
                                blockId: 'test'
                            }
                        }
                    },

                    {
                        title: "Cartelera",
                        to: {
                            name: "PagePersonHighlights",
                            params: {
                                personId: person.id
                            }
                        }
                    },

                    {
                        title: "Calendario",
                        to: {
                            name: "PagePersonCalendar",
                            params: {
                                personId: person.id
                            }
                        }
                    },

                    {
                        title: "Mensajería",
                        to: {
                            name: "PagePersonMail",
                            params: {
                                personId: person.id
                            }
                        }
                    },

                    {
                        title: "Datos personales",
                        to: {
                            name: "PagePersonProfile",
                            params: {
                                personId: person.id
                            }
                        }
                    }
                ]
            }
        }
    }
}