export default {
	"common.Accept": "Accept",
	"common.Add": "Add",
	"common.Cancel": "Cancel",
	"common.Close": "Close",
	"common.Delete": "Delete",
	"common.Save": "Save",

	"common.accept": "accept",
	"common.add": "add",
	"common.cancel": "cancel",
	"common.close": "close",
	"common.delete": "delete",
	"common.save": "save",
}