import Vue from 'vue';

import store from '@/store/index.js';

Vue.filter("bytes", (bytes, precision) => {
	if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
	if (typeof precision === 'undefined') precision = 1;
	var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
	var	number = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
});

Vue.filter("person", (value) => {
	return value != null ? value.lastname + ", " + value.firstname : '';
});

Vue.filter("currency", (value) =>{
    var number = parseFloat(value);
    if (isNaN(number)) {
        number = 0;
    }

	let money = store.getters.getSetting("phidias.currency_postfix");	
	let currency = money ? money : store.state.url && store.state.url.includes('.es') ? 'EUR' : 'COP';
    
	return number.toLocaleString(store.state.i18n.language, {style:'currency', currency});
});




