<template>
  <div class="phi-block-v3">
    <template v-if="!action">
      
      <div class="exception_alert" v-if="exceptionText">
        <p>Información no disponible:</p>
        {{ exceptionText }}
      </div>

      <div v-html="body" v-else></div>

      <router-link
        class="phi-block"
        v-if="paymentButton && debitId && body"
        :to="{name: 'billing-debit-debitId', params:{debitId: debitId}}"
      >
        <div class="gopay phi-media phi-card">
          <mu-icon value="credit_card" class="phi-media-figure"></mu-icon>
          <div class="phi-media-body">PAGAR POR TESORERIA</div>
          <mu-icon value="arrow_forward" class="phi-media-right"></mu-icon>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  phiBlock: {
    title: "V3",
  },

  name: "phi-block-v3",
  props: {
    block: {
      required: false,
    },

    action: {
      required: false,
    },

    paymentButton: {
      required: false,
      default: store.state.settings && store.state.settings.tuCompra,
    },
  },

  data() {
    return {
      body: null,
      debitId: null,
    };
  },

  created() {
    this.load();
  },

  computed: {
    exceptionText() {
      if (!this.body) {
        return null;
      }

      if (this.body.indexOf("exception 'Application_Exception'") >= 0) {
        return "Parámetro especificado no encontrado o borrado";
      }

      if (this.body.indexOf("exception 'Authorization_Exception'") >= 0) {
        return "Verifique permisos relacionados con el link del módulo";
      }
    },
  },

  methods: {
    load() {
      if (!this.block.url) return;
      fetch(this.block.url, {
        headers: {
          Authorization: "Bearer " + store.state.token,
        },
      })
        .then((response) => response.text())
        .then((body) => this.body = body);

      if (this.paymentButton && this.block.url.search("/debit/details") > 0) {
        let regex = /debit=(\d+)/g;
        //let matches = [];
        this.block.url.replace(regex, (match, debit_id) => {
          //matches.push(debit_id);
          this.debitId = debit_id;
        });
        //console.log(this.debit);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gopay {
  cursor: pointer;
  margin-top: 24px;
  background-color: rgb(28, 137, 184);
  color: #fff;
  align-items: center;

  &:hover {
    background-color: rgb(36, 180, 242);
  }

  .phi-media-body {
    font-size: 1.2em;
  }
}

.exception_alert {
  opacity: 0.9;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  background: #ef5350;
  border: 1px solid #b71c1c;
  font-size: 12px;

  p {
    margin: 0;
    font-weight: bold;
    margin-bottom: 7px;
  }
}
</style>
