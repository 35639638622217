import Vue from 'vue'
import i18next from "i18next"

import langDe from "./languages/de.js"
import langEn from "./languages/en.js"
import langEs from "./languages/es.js"
import langFr from "./languages/fr.js"
import langIt from "./languages/it.js"
import langPt from "./languages/pt.js"

import { format, formatRelative, differenceInDays } from 'date-fns'


const vm = new Vue({data: {tag: 0}});

i18next.$appendLanguage = function(language, dictionary) {
    // Set dictionary, overwriting all existing
    i18next.addResourceBundle(language, "translation", dictionary, true, true);
    vm.tag++; // triggers reactivity
};

i18next.init({
    debug: false,
    fallbackLng: "en",
    keySeparator: false,

    resources: {
        de: {translation: langDe},
        en: {translation: langEn},
        es: {translation: langEs},
        fr: {translation: langFr},
        it: {translation: langIt},
        pt: {translation: langPt}
    }
});

export default {
    install(Vue, options) {

        Vue.mixin({
            beforeCreate() {
                this.$terms = {};

                if (!this.$options.i18n) {
                    return;
                }

                for (let lang in this.$options.i18n) {
                    if (this.$options.i18n.hasOwnProperty(lang)) {
                        // Add components dictionary (without overwriting existing)
                        i18next.addResourceBundle(lang, "translation", this.$options.i18n[lang], true);
                    }
                }
            },

            methods: {
                $date(timestamp, dateFormat = null) {  // dateFormat:  day, time, date
                    if (!timestamp) {
                        return timestamp;
                    }

                    let date = typeof timestamp == "object" ? timestamp : new Date(timestamp * 1000);
                    let language = this.$store && this.$store.state.i18n ? this.$store.state.i18n.language : undefined;
                    let dateFnsLocale = this.$store && this.$store.state.i18n ? this.$store.state.i18n.dateFns.locale : undefined;

                    switch (dateFormat) {
                        case "day":
                            // 02/28/2004,  2.28.2004,
                            return date.toLocaleDateString(language);

                        case "time":
                            // 23:45,  11:45 p.m.
                            return date.toLocaleTimeString(language, {hour: '2-digit', minute:'2-digit'}).replace(". m.", ".m.");

                        case "date":
                            // 3 mar 2017, 13:41,   3 mar 2017, 1:41 p. m.,   3 Mär, 2017, 13:41

                            // date-dns no tiene locale para es-CO (que usa am/pm), asi que
                            // usaremos solo el formato de fecha ISO de date-fns ("PP") y lo concatenamos con
                            // la hora generada por toLocaleTimeString (que SI maneja es-CO)
                            // return format(date, "PPp", { locale: dateFnsLocale });
                            return format(date, "PP", { locale: dateFnsLocale }) + ", " + this.$date(timestamp, "time");

                        case null:
                            let diff = Math.abs(differenceInDays(new Date(), date));
                            return diff > 6 ?
                                this.$date(timestamp, "date").replace(' '+(new Date()).getFullYear(),'').replace(",,",",") // Hide the current year
                                :
                                formatRelative(date, new Date(), { locale: dateFnsLocale });

                        default:
                            return format(date, dateFormat, { locale: dateFnsLocale });
                    }
                }
            }
        });

        Vue.prototype.$t = function(string, params) {
            this.$terms[string] = string;

            vm.tag; // triggers reactivity
            return i18next.t(string, params);
        };
    }
}