export default {
    state: {
        personId: null,
        collection: null
    },

    actions: {
        ready(context) {
            /* Prefetch types for current person */
            context.dispatch("fetchPersonTypes", context.rootState.user.id);
        },

        fetchPersonTypes(context, personId) {
            if (!personId) {
                return;
            }

            let collection = context.rootState.api.collection(`/people/${personId}/threads2/types`);
            collection.fetch();
            context.commit("setCollection", collection);
            context.commit("setPersonId", personId);
        }
    },

    mutations: {
        setCollection(state, collection) {
            state.collection = collection;
        },

        setPersonId(state, personId) {
            state.personId = personId;
        },

        decreaseUnread(state, type) {
            if (!state.collection || !state.collection.items) {
                return;
            }

            let typeId;
            if (typeof type == "object") {
                typeId = type.id ? type.id : (type.singular ? type.singular : JSON.stringify(type));
            } else {
                typeId = type;
            }

            for (let k = 0; k < state.collection._items.length; k++) {
                let kType = state.collection._items[k];
                let kTypeId = kType.id ? kType.id : (kType.singular ? kType.singular : JSON.stringify(kType));

                if (kTypeId == typeId) {
                    state.collection._items[k].unread = Math.max(parseInt(state.collection._items[k].unread) - 1, 0);
                    state.collection.override();
                }
            }
        }
    },

    getters: {
        types(state) {
            return state.collection ? state.collection.items : [];
        },

        getType(state) {
            return (typeId) => {
                let allTypes = state.collection ? state.collection.items : [];

                for (let k = 0; k < allTypes.length; k++) {
                    if (allTypes[k].id == typeId || allTypes[k].singular == typeId) {
                        return allTypes[k];
                    }
                }

                return {
                    "id": null,
                    "singular": null,
                    "plural": null,
                    "icon": null,
                    "color": null
                };
            }
        }
    }
}